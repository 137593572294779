.kd-text {
    &-light {
        font-weight: $text_weight_medium;
    }
}

.kdng {
    // &-suggested-tag-new {
    //     @extend .badge-new;
    //     @extend %tags-m;
    // }
    // &-suggested-tag-sale {
    //     position: absolute;
    //     color: $color-white;
    //     text-align: right;
    //     padding: 5px 12px 5px 5px;
    //     font-size: 14px;
    //     font-weight: 800;
    //     bottom: 16px;
    //     right: 1px;
    //     min-width: 80px;
    //     background-color: darken($color-instagram, 20%) !important;
    //     background-color: transparent !important;
    //     @extend %tags-m;
    // }
    .form-control {
        display: inline;
    }
    &-numbers {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    &-dialog {
        p {
            background: $color-secondary;
            text-align: center;
            color: $color-white;
        }
    }
    &-modal {
        background: $color-grey-light2;
        .icon-info {
            position: static !important;
            font-size: 1rem;
        }
    }
    &-custom-dropdown {
        background: $color-white;
        border: 1px solid $color-secondary;
        padding: 10px;
        border-radius: $default-border-radius;
        &:after {
            // @extend .dropdown-toggle::after;
            white-space: nowrap;
            // Generate the caret automatically
            @include caret;
            position: absolute;
            right: 15px;
            top: 20px;
        }
        &.disabled {
            border: 1px solid $color-black-t3;
            color: $color-black-t3;
        }
    }
    &-disbaled-btn {
        @extend .btn;
        cursor: default !important;
    }
    &-rotate {
        padding: 5rem;
    }
    .icon-cancel {
        font-size: 23px;
        display: inline-block;
    }
    &-colour-header {
        background: $color-white !important;
        a {
            color: $color-black !important;
            font-weight: 400;
            font-size: 0.9rem;
        }
    }
    // &-swatch-headings {
    //     @extend %margins;
    // }
    &-colour-main {
        background: $color-error;
        @extend %swatches;
    }
    &-colour-secondary {
        background: lighten($color-error, 10%);
        @extend %swatches;
    }
    &-colour-default {
        background: adjust-hue($color-error, 50%);
        @extend %swatches;
    }
    &-swatches {
        padding: 15px 0;
    }
    &-selected {
        color: $color-grey-dark2;
        @extend %margins;
        margin-top: 7px;
    }
    // &-tablet{
    // 	@include media-breakpoint-down(lg){
    // 	height:500px;
    // 	overflow-y:scroll;
    //  overflow-x: hidden;
    // 	padding-right:2rem;
    // 	}
    // }
}

// .shop-item-kdng-bag {
//     @extend .shop-item;
// }
#kdlogoAccordion .card a[data-toggle="collapse"]:not(:first-child) {
    position: relative;
    display: block;
    &:before {
        content: "";
        position: absolute;
        right: 8px;
        top: 0;
        width: 4px;
        height: 20px;
        background-color: black;
        transform: rotate(-90deg);
        transition: 0.2s ease;
        font-size: 2rem;
    }
    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 8px;
        width: 20px;
        height: 4px;
        background-color: black;
        font-size: 2rem;
    }
    &.collapsed {
        &:before {
            transform: rotate(0deg);
        }
    }
}

.kit_color--selector {
    height: 26px;
    width: 26px;
    margin: 8px;
    cursor: pointer;
}

.kdng-colours-accordion {
    .icon-right {
        position: absolute;
        top: 8px;
        right: 10px;
        transition: all 0.3s ease;
        font-size: 22px;
        color: $color-grey-dark1;
        @include media-breakpoint-up(md) {
            top: 15px;
        }
        &:before {
            font-size: 24px;
        }
    }
    a[aria-expanded="true"] {
        .icon-right {
            transform: rotate(-180deg);
        }
    }
}

.boards {
    //margin: 3px;
    cursor: pointer;
    //border-radius: 50%;
    .icon-check {
        font-size: 16px;
        margin-right: 0;
        font-weight: 600;
        color: white;
        text-align: center;
        position: absolute;
        left: 50%;
        width: 28px;
        height: 28px;
        margin-left: -14px;
        top: 0;
        margin-top: 6px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.36);
    }
}

// .kdng-breadcrumbs {
//     @include media-breakpoint-down(md) {
//         flex-direction: column;
//         margin: 5px 0;
//     }
//     li {
//         &:nth-child(15),
//         &:nth-child(12) {
//             width: 50px !important;
//         }
//         &.progress {
//             width: 33px;
//             margin-top: 1.5em;
//         }
//         &.bar {
//             height: 2px;
//             background: grey;
//             width: 150px;
//             margin-top: 12px;
//         }
//         &.stages {
//             margin: 0 12px;
//         }
//     }
//     .completed {
//         @extend %checks;
//         @extend .icon-check;
//         h6 {
//             color: $color-black;
//             font-weight: $text_weight_regular;
//             &:after {
//                 border: 2px solid $color-black;
//                 width: 200px;
//                 height: 10px;
//                 content: "";
//             }
//         }
//     }
//     .incomplete {
//         @extend %checks;
//         background: grey;
//     }
//     .in-progress {
//         @extend %checks;
//         background: $color-secondary;
//         &:afer {
//             content: "";
//         }
//     }
// }
.current {
    background: teal;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    border: 1px solid red;
}

.strip-message {
    @extend .promo-bar;
    width: 100%;
    min-height: 50px;
    top: 0;
    display: inline-block;
    a {
        padding: 0;
    }
}

.strip-message p {
    float: left;
    color: $color-white;
    font-weight: 800;
    text-align: center;
    padding: 4px 24px 0 24px;
    margin-top: 7px;
    background-color: $color-secondary;
    @include media-breakpoint-down(sm) {
        width: 90%;
    }
}

.strip-message input[type="checkbox"]+label:before {
    color: $color-warning;
    position: absolute;
    left: 80%;
    padding-top: 7px;
}

.shop-item-kdng-bag {
    @extend .shop-item;
}

.kitbag-btn {
    @extend .btn--secondary;
    padding: 10px 0 !important;
}

#selectLogo-0>img,
.dropdown-item>img {
    max-height: 24px;
    width: auto;
}

.dropdown-item svg {
    max-height: 24px;
}

span.svg-text__selected {
    max-height: 21px;
}

span.svg-text__selected svg {
    float: left;
    max-height: 21px;
}

#kdng-colours,
#kdng-logos,
#kdng-names,
#kdng-features {
    // position: fixed;
    // background: rgba(255, 255, 255, 0.925);
    // z-index: 10;
    // top: calc(100vh - 87px);
    // bottom: auto;
    // transition: all 0.35s ease;
    // left: 0;
    // border-top: 1px solid rgba(0, 0, 0, 0.125);
    position: fixed;
    bottom: -6px;
    left: 0;
    z-index: 1100;
    // background: rgba(255, 255, 255, 0.925);
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    transition: all 0.35s ease;
    // opacity: 0;
    // box-shadow: 0px 1px 35px rgba(125, 125, 125, 0.25);
    // Added to stop the jerky transition animation on KDL Mobile Menu
    .collapsing {
        transition: none !important;
    }
}

// #kdng-features {
// 	transform: translateY(144px);
// }
// @media (min-width: 768px) {
// 	#kdng-features {
// 		transform: translateY(168px);
// 	}
// }
// #kdng-colours {
//     transform: translateY(104px);
// }
//  @media (min-width: 768px) {
//     #kdng-colours {
//     transform: translateY(168px);
//     }
//  }
// #kdng-logos {
//     transform: translateY(180px);
// }
// @media (min-width: 768px) {
// 	#kdng-logos {
// 		transform: translateY(276px);
// 	}
// }
// #kdng-names {
//     transform: translateY(222px);
// }
// @media (min-width: 768px) {
// 	#kdng-names {
// 		transform: translateY(302px);
// 	}
// }
@media (min-width: 992px) {
    #kdng-features,
    #kdng-colours,
    #kdng-names,
    #kdng-logos {
        position: relative;
        z-index: 1;
        top: auto;
        bottom: auto;
        border-top: 0;
        // background: transparent;
        transition: none;
        background: #fafafa;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 3px;
    }
}

h3 {
    font-size: 1.25rem;
}

@media (min-width: 992px) {
    h3 {
        font-size: 1.75rem;
    }
}

#kdng-options {
    position: fixed;
    background: rgba(255, 255, 255, 0.925);
    z-index: 10;
    top: calc(100vh - 92px);
    bottom: auto;
    transition: all 0.35s ease;
    left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.slide-up {
    transform: translateY(-48px) !important;
}

@media (min-width: 992px) {
    .slide-up {
        transform: translateY(0px) !important;
    }
}

// .slide-active {
// 	opacity: 1 !important;
// 	z-index: 1200 !important;
// }
.kdng-colour-default,
.kdng-colour-main,
.kdng-colour-secondary {
    width: 32px;
    height: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.125);
}

.card-header {
    background: transparent !important;
}

@media (min-width: 992px) {
    .card-header {
        background: #fff !important;
    }
}

.card-header .kdng-colour {
    width: 15px;
    height: 100%;
    display: block;
    float: left;
    position: absolute;
    left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: none;
}

.btn-kitbag__add {
    position: fixed;
    z-index: 1200;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #181818;
}

.custom-file-control:after,
.btn--secondary {
    font-weight: 600;
    padding: 8px 15px;
    background-color: #282828;
}

button.icon-close {
    font-size: 20px;
    background: #282828;
    color: white;
    border-radius: 0 3px 3px 0;
    border: 1px solid transparent;
    border-left: 1px solid white;
}

.custom-file-control:after {
    border-radius: 3px 0 0 3px;
}

// TODO: Create a new class to apply on top of the button
// btn-border-left__white-1
//   .btn.btn--secondary {
//     border-left: 1px solid white;
//   }
.logo-wrapper {
    margin-bottom: 0px;
}

img.mh-144 {
    max-height: 96px;
    width: auto;
    padding: 0;
}

.icon-keyboard_arrow_down:before,
.icon-keyboard_arrow_left:before,
.icon-keyboard_arrow_right:before,
.icon-keyboard_arrow_up:before {
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
}

.btn-logo-position {
    border-radius: 50%;
    background: 0 0;
    border: 1px solid white;
    border-radius: 3px;
    height: 33px;
    width: 33px;
    cursor: pointer;
    margin: 0;
    background: #282828;
    color: white;
    padding: 0;
    // margin: 5px;
    &.disabled {
        background: rgba(0, 0, 0, 0.36);
        // cursor: not-allowed;
    }
}

.alert {
    padding: 0.5rem 1rem;
}

.kdng-tabs-header {
    // background: rgba(0, 0, 0, 0.05);
    // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    // text-align: center;
    // width: 100%;
    // button {
    // 	// text-shadow: 0px 1px 1px rgba(100, 100, 100, 0.9);
    // }
    h4 {
        font-size: 1.125rem;
        display: inline-block;
        margin-bottom: 0;
        line-height: 41px;
    }
    .btn {
        border-radius: 0px;
        // background: #282828;
        background: #ebebeb;
        color: #181818;
        font-weight: 500;
    }
    .selected {
        background: white;
        border-right: 1px solid rgba(0, 0, 0, 0.125);
        font-weight: 600;
        box-shadow: none;
    }
    &__desktop {
        z-index: 1;
        margin-bottom: -1px;
        .btn {
            border: 1px solid #ebebeb;
            // &:hover,
            &:focus {
                box-shadow: none;
            }
        }
        .selected {
            background: #fafafa;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-bottom: 1px solid #fafafa;
        }
    }
}

.kdng-attribute {
    background: #fff;
    // border: 1px solid rgba(0,0,0,0.125);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    cursor: pointer;
    &__img {
        background: #f0f0f0;
        // border-bottom: 1px solid rgba(0,0,0,0.125);
        svg {
            display: block;
            margin: auto;
            max-width: 100%;
            min-height: 150px;
            padding: 5px;
        }
    }
    &__text {
        padding: 8px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
            padding: 15px;
        }
    }
}

.selected {
    // border: 1px solid slategrey;
    //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 121, 107, 0.65), 0 3px 6px rgba(0, 121, 107, 0.65);
}

svg,
path {
    transition: fill 0.35s ease;
}

// #kdng-colours-accordion {
//     .slick-list {
// 		// padding: 0 30px !important;
// 		padding: 0 20% 0 0 !important;
// 	}
// }
.kdng-features .slick-list {
    padding: 0 20% 0 2% !important;
}

.logo-search__image-wrapper {
    background: rgba(0, 0, 0, 0.175);
    border: 1px solid rgba(0, 0, 0, 0.2);
    img {
        display: block;
        width: auto;
        height: auto;
        max-height: 120px;
        padding: 15px;
    }
}

.bl-0 {
    border-left: 0 !important;
}

.bl-1-white {
    border-left: 1px solid white;
}

.btn-outline__grey {
    background: #ebebeb;
    color: #282828;
    border: 1px solid rgba(0, 0, 0, 0.25);
    font-weight: 500;
    padding: 10px 12px;
    border-radius: 0;
}

.kdng-attribute__img--dark {
    background: #3c3c3c;
    svg {
        display: block;
        margin: auto;
        max-width: 100%;
        min-height: 72px;
        padding: 0;
    }
}

.dummy {
    display: none;
}