.white {
    color: $color-white !important;
}

.color {
    &--black-t1 {
        color: $color-black-t1;
    }
    &--black-t2 {
        color: $color-black-t2;
    }
}

.bg {
    &-white {
        background: $color-white;
    }
    &-grey-light1 {
        background: $color-grey-light1;
    }
    &-grey-light2 {
        background: $color-grey-light2;
    }
    &-grey-light3 {
        background: $color-grey-light3;
    }
    &-grey-dark1 {
        background: $color-grey-dark1;
    }
    &-grey-dark2 {
        background: $color-grey-dark2;
    }
    &-grey-dark3 {
        background: $color-grey-dark3;
    }
    &-warning {
        background: $color-warning;
        font-size: 0.9rem;
    }
}
