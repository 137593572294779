.alert-a {
  text-decoration: underline;
  font-weight: 500;
  color: inherit;
  &:hover {
    font-weight: 600;
    text-decoration: underline;
  }
}

.return-alert {
  text-align: center;
}

.return-link {
  color: #00a695;
}

#timeline-wrap{
  margin: 50px 30%;
  position: relative;
  @media (max-width: 640px) {
    margin: 50px 20%;
  }
  @media (max-width: 420px) {
    margin: 50px 15%;
  }
}

#timeline{
  height: 1px;
  width: 100%;
  background-color: #d0d0d0;
  position: relative;
}

.timeline-text {
  color: #232323;
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  top: -45px;
}

.t1 {
  left: -50px;
  @media (max-width: 700px) {
    left: -28px;
    width: 50px;
    top: -60px;
    line-height: normal;
  }
}

.t2 {
  left: 40%;
  @media (max-width: 1200px) {
    left: 34%;
  }
  @media (max-width: 800px) {
    left: 30%;
  }
  @media (max-width: 700px) {
    left: 33%;
    width: 70px;
    top: -60px;
    line-height: normal;
    text-align: center;
  }
  @media (max-width: 640px) {
    left: 37.5%;
  }
  @media (max-width: 565px) {
    left: 36%;
  }
  @media (max-width: 500px) {
    left: 34%;
  }
  @media (max-width: 420px) {
    left: 33%;
  }
  @media (max-width: 375px) {
    left: 31%;
  }
  @media (max-width: 325px) {
    left: 28%;
  }
}

.t3 {
  left: 89%;
  width: 120px;
  @media (max-width: 1200px) {
    left: 82%;
  }
  @media (max-width: 700px) {
    left: 84%;
    width: 70px;
    top: -60px;
    line-height: normal;
    text-align: center;
  }
  @media (max-width: 640px) {
    left: 88%;
  }
  @media (max-width: 565px) {
    left: 86%;
  }
  @media (max-width: 420px) {
    left: 84%;
  }
  @media (max-width: 325px) {
    left: 80%;
  }
}

.marker{
  z-index:1000;
  color: #fff;
  width: 35px;
  height: 35px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  margin-left: -25px;
  border-radius: 50%;
}

.timeline-icon.one {
  background-color: #232323;
}

.timeline-icon.two {
  background-color: #d0d0d0;
}

.timeline-icon.three{
  background-color: #d0d0d0;
}

.timeline-marker-black {
  background-color: #232323 !important;
}


.mfirst{
  top: -18px;
}

.m2{
  top: -18px;
  left: 49.5%
}

.mlast{
  top: -18px;
  left: 100%
}

.returns-row {
  justify-content: center;
  margin: 0 150px;
  @media (max-width: 1200px) {
    margin: 0 3rem;
  }
  @media (max-width: 990px) {
    margin: 0 3rem;
  }
}

.returns-row-inner {
  flex-basis: 20%;
}

.returns-checkbox {
  position: absolute;
  right: 2%;
}

.returns-page {
  @media (max-width: 990px) {
    max-width: 940px;
  }
  @media (max-width: 768px) {
    max-width: 760px;
  }
}

.returns-reason {
  display: inline-flex;
  max-width: 100%;
  flex-basis: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.returns-quantity {
  width: 60px;
}

.fb-25 {
  flex-basis: 25%;
}

.fb-75 {
  flex-basis: 75%;
}

.submit-btn-div {
  margin-top: 20px;
}

.hide {
  display: none;
}

.returns-textarea {
  width: 394px;
  @media (max-width: 575px) {
    width: 350px;
  }
}

.textarea-div {
  flex-basis: 100%;
  padding: 15px;
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#returns-search-box {
  position: absolute;
  padding: 10px 0 0 10px;
  @media (max-width: 760px) {
    padding: 0;
    position: initial;
  }
}

#return-search-btn {
  border: none;
  background: #1e1e1e;
  color: white;
  border-radius: 5%;
  padding: 3px 13px;
  @media (max-width: 760px) {
    display: none;
  }
}

#return-order-products {
  @media (min-width: 1170px) {
    min-width: 1000px;
  }
  @media (max-width: 1169px) {
    min-width: 800px;
  }
  @media (max-width: 900px) {
    min-width: 500px;
  }
  @media (max-width: 550px) {
    min-width: fit-content;
  }
}

#returns-search-box > form > input {
  @media (max-width: 760px) {
    flex-basis: 100%;
  }
}


////////////////////////////////////////////
// confirmation popup

.scroll-container:nth-child(even) {
  background: #f7f7f7;
}

#returns-confirmation-popup {
  position: absolute;
  background-color: white;
  z-index: 10001;
  top: 170px;
  left: 25%;
  padding: 20px;
  width: 50%;
  border: 1px solid #d4d4d4;
  @media (max-width: 870px) {
    width: 70%;
    left: 14%;
  }
  @media (max-width: 525px) {
    width: 90%;
    left: 5%;
  }
}

.scroll-container {
  overflow-y: scroll;
  max-height: 320px;
  margin-bottom: 10px;
  padding-right: 20px;
}

.popup-content-container {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.return-product-img-div {
  flex-basis: 16%;
  padding-right: 10px;
  margin: auto;
  &__summary {
    flex-basis: 20%;
  }
  @media (max-width: 870px) {
    flex-basis: 30%;
  }
}

.return-product-details {
  flex-basis: 50%;
  &__summary {
    flex-basis: 80%;
  }
  @media (max-width: 870px) {
    flex-basis: 70%;
  }
}

.return-product-title {
  font-weight: 500;
  font-size: 17px;
  @media (max-width: 1070px) {
    font-size: 15px;
  }
}

.product-info-item {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  border-top: 1px solid #d2d2d2;
  padding-top: 5px;
  margin-top: 5px;
  font-size: smaller;
  @media (max-width: 1070px) {
    font-size: small;
  }
}

.fb-30 {
  flex-basis: 33%;
  padding-right: 2px;
  @media (max-width: 1070px) {
    padding-right: 5px;
  }
}

.fb-return {
  @media (max-width: 870px) {
    flex-basis: 33%;
    padding-right: 5px;
  }
}

.returns-info {
  flex-basis: 34%;
  padding-left: 10px;
  @media (max-width: 870px) {
    flex-basis: 100%;
    padding-left: 0;
  }
}

.detail-text {
  color: #969696;
}

.return-info-detail {
  border-top: 1px solid #d2d2d2;
  margin-top: 5px;
  padding-top: 5px;
  font-size: smaller;
  font-weight: 500;
  @media (max-width: 1070px) {
    font-size: small;
  }
  @media (max-width: 870px) {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.blur-background {
  background: black;
  width: 100%;
  height: 100%;
  opacity: 70%;
  position: absolute;
  z-index: 10000;
  top: 0;
}

#confirm-btns-div {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.return-btn {
  flex-basis: 48%;
}

#confirm-btn {
  color: white;
  background: #232323;
}

#cancel-btn {
  background: #e3e3e3;
}

.return-popup-btn {
  width: 100%;
  border: none;
  padding: 5px 0;
  font-weight: 500;
  letter-spacing: 1px;
}

////////////////////////////////////////////
// arrange delivery/hermes component

#return-delivery-div {
  text-align: center;
  margin-top: 20px;
}

#top-text {
  margin-bottom: 35px;
}

.bold-text {
  font-weight: 600;
}

#rma-title {
  letter-spacing: 1.5px;
}

#rma-number {
  border-bottom: 1px solid #d0d0d0;
  width: 200px;
  font-size: larger;
  font-weight: 500;
  margin: 20px auto 10px auto;
  padding-bottom: 20px;
}

.bold-italic {
  margin-bottom: 35px;
  font-style: italic;
  font-size: small;
  font-weight: 500;
}

#next-steps {
  letter-spacing: 1.5px;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}

#instructions {
  line-height: 35px;
  list-style: numbered;
  list-style-position: inside;
}

body > my-app > div > return-arrange-delivery > div > div:nth-child(3) > ul > li::marker {
  font-weight: 500;
}

#hermes-link {
  margin: 20px 0;
}

.return-page-link {
  text-decoration: underline;
  font-weight: 500;
  &:hover {
    color: #8a8a8a;
  }
}

#support-text {
  font-size: small;
  margin-top: 50px;
}