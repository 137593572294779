.bold {
  font-weight: bold;
}

.time {
  position: absolute;
  left: -145px;
}

.timeline-wrapper {
  padding-left: 25%;
  min-width: 400px;
  font-size: 16px;
  margin: 50px 0;
  flex-basis: 70%;
  @media (max-width: 1000px) {
    flex-basis: 100%;
    padding-left: 40%;
    margin-top: 80px;
  }
  @media (max-width: 850px) {
    margin-top: 40px;
    padding-right: 20px;
  }
  @media (max-width: 450px) {
    margin-bottom: 40px;
    padding-right: 40px;
  }
}

.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}
.StepProgress::before {
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 87%;
  border-left: 2px solid #CCC;
  @media (max-width: 450px) {
    height: 85%;
  }
}

.StepProgress-item,
.StepProgress-item-end {
  position: relative;
  counter-increment: list;
}
.StepProgress-item:not(:last-child) {
  padding-bottom: 20px;
}
.StepProgress-item::before,
.StepProgress-item-end::before {
  display: inline-block;
  content: '';
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}
.StepProgress-item::after,
.StepProgress-item-end::after{
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: -35px;
  width: 12px;
  height: 12px;
  border: 2px solid #CCC;
  border-radius: 50%;
  background-color: #FFF;
}

.StepProgress-item.is-done::before {
  border-left: 2px solid #a7a7a7;
}
.StepProgress-item.is-done::after {
  font-size: 10px;
  color: #FFF;
  text-align: center;
  border: 8px solid #3c3c3c;
  background-color: #3c3c3c;
  left: -37px;
}

.StepProgress strong {
  display: block;
}

.status-description {
  color: #a5a5a5;
  @media (max-width: 620px) {
    font-size: small;
  }
}

.StepProgress-item.status-current::after {
  font-size: 10px;
  color: #FFF;
  text-align: center;
  border: 3px solid #3c3c3c;
  background-color: #bebebe;
  width: 25px;
  height: 25px;
  left: -42px;
}

#rmaNumber {
  font-weight: 500;
  font-size: large;
  color: #878787;
  @media (max-width: 450px) {
    font-size: medium;
  }
}

.tracker-title {
  margin-left: 4rem;
  padding-top: 2rem;
  position: relative;
  @media (max-width: 450px) {
    margin-left: 2rem;
    position: absolute;
    top: 50px;
  }
  button {
    position: absolute;
    top: 2rem;
    right: 4rem;
  }
}

.tracker-title-act {
  @media (max-width: 545px) {
    font-size: 30px;
  }
}

.tracker-wrapper {
  display: flex;
  @media (max-width: 850px) {
    flex-wrap: wrap-reverse;
  }
}

.summary-wrapper {
  margin: 50px 0;
  flex-basis: 30%;
  border-left: 1px solid black;
  height: fit-content;
  padding-right: 10px;
  @media (max-width: 1000px) {
    position: absolute;
    top: 135px;
    right: 110px;
  }
  @media (max-width: 990px) {
    top: 75px;
  }
  @media (max-width: 850px) {
    position: inherit;
    border: none;
    flex-basis: 100%;
    margin: 10px 53px;
    display: inline-flex;
    color: #808080;
  }
  @media (max-width: 450px) {
    margin: 10px 0;
    position: absolute;
    top: 170px;
    left: 32px;
    width: 100%;
  }
}

#return-summary {
  font-size: 30px;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 10px;
  padding-left: 10px;
  @media (max-width: 1170px) {
    font-size: 25px;
  }
  @media (max-width: 850px) {
    display: none;
  }
}

.summary-info {
  padding-left: 10px;
  margin-bottom: 5px;
}

#view-items {
  border: none;
  background: none;
  font-weight: 500;
  padding-left: 10px;
  &:hover {
    font-weight: 600;
  }
  @media (max-width: 850px) {
    color: #808080;
    margin-top: -5px;
    text-decoration: underline;
  }
}

.tracker-container {
  width: 80%;
  background: white;
  margin: auto auto 2rem auto;
  @media (max-width: 450px) {
    width: 95%;
    margin: 10rem auto 2rem auto;
  }
}

//////////////////////////////////////////
// Popup - return items summary

#return-items-container {
  position: absolute;
  z-index: 10000;
  top: 20%;
  left: 25%;
  width: 800px;
  background: white;
  border: 1px solid #d4d4d4;
  padding: 20px 20px 0 20px;
}

.close-button {
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 500;
}

.fb-60 {
  flex-basis: 66%;
}

.hermes-warning {
  text-align: center;
  margin: 20px 50px 0 50px;
}

#cancelReturnPopup {
  z-index: 10000;
  position: absolute;
  background: white;
  padding: 2rem 3rem;
  top: 30%;
  div {
    display: flex;
    flex-wrap: wrap;
  }
  .main-btn {
    margin-right: 3rem;
  }
}

.main-btn {
  border: none;
  background: #232323;
  color: white;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  &:hover {
    background: #737373;
  }
}

.secondary-btn {
  border: none;
  background: #e9e9e9;
  color: #232323;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  &:hover {
    background: #fbfbfb;
  }
}

#back-to-orders {
  padding: 1rem 0 0 4rem;
  font-size: smaller;
  font-weight: 500;
  a {
    margin-left: 1rem;
  }
}