.login-tabs {
    margin-top: 20px;
    @extend .text_size_subheader;
    border-bottom: 1px solid $color-divider;
    a {
        position: relative;
        padding: 8px 0;
        display: block;
        text-align: center;
        &.active {
            font-weight: 600;
            color: $color-secondary;
            &:after {
                content: '';
                display: block;
                height: 2px;
                background-color: $color-secondary;
                width: 100%;
                position: absolute;
                bottom: 0;
            }
        }
    }
}

#tab-login,
#tab-register {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
    &.active {
        display: block;
    }
}