.modal {
    z-index: 2050;
    &-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.185);
    }
    &-backdrop {
        opacity: 0.75;
        z-index: 2000;
    }
    &-md {
        max-width: 580px;
    }
    &-lg {
        max-width: 1024px;
    }
    button {
        &.close {
            background: $color-grey-dark3;
            border: 0;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 24px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            line-height: 30px;
            padding: 0;
            color: #fff;
        }
    }
}

.icon-close--modal {
    position: absolute;
    color: $color-white;
    top: 0px;
    right: -60px;
    font-size: 30px;
    // &:hover {
    //     cursor: pointer;
    // }
}

#size-guide-modal {
    .modal-dialog {
        max-width: 768px;
    }
    .icon-keyboard_arrow_down {
        right: 15px;
    }
    #accordion-size-guide {
        margin: 0 -15px 24px;
    }
}