// Hamburgers
// @description Tasty CSS-animated hamburgers
// @author Jonathan Suh @jonsuh
// @site https://jonsuh.com/hamburgers
// @link https://github.com/jonsuh/hamburgers
// Settings
// ==================================================
$hamburger-layer-width: 20px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: #FFFFFF !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-layer-border-radius: 2px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(80%) !default;
// Hamburger
// ==================================================
.hamburger {
    position: relative;
    z-index: 999;
    padding: 20px 30px 16px 0;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function; // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    // &:hover {
    //     @if $hamburger-hover-use-filter==true {
    //         filter: $hamburger-hover-filter;
    //     }
    //     @else {
    //         opacity: $hamburger-hover-opacity;
    //     }
    // }
    &:focus {
        outline: none;
    }
}

.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;
    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }
    &::before,
    &::after {
        content: "";
        display: block;
    }
    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}

.hamburger--spin {
    .hamburger-inner {
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        &::before {
            transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
        }
        &::after {
            transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }
    &.active {
        .hamburger-inner {
            transform: rotate(225deg);
            transition-delay: 0.14s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
            }
            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}