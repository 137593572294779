// /**
//  * [svg-bg description]
//  *
//  * Usage:
//  * @include svg-bg('file-name', $repeat: repeat);
//  */

// @mixin svg-bg($name, $extension: png, $color: transparent, $position: 0 0, $repeat: no-repeat) {
//     background: url(../images/css/#{$name}.#{$extension}) $color $position $repeat;
//     background: none,
//     url(../images/css/#{$name}.svg) $color $position $repeat;
// }

// /**
//    * [Creates a CSS triangle, which can be used for dropdown arrows, dropdown pips, and more. Use this mixin inside a `&::before` or `&::after` selector, to attach the triangle to an existing element.]
//    * @param  {Number} $triangle-size - Width of the triangle.
//    * @param  {Color} $triangle-color - Color of the triangle.
//    * @param  {Keyword} $triangle-direction - Direction the triangle points. Can be `up`, `right`, `down`, or `left`.
//    * @link   http://foundation.zurb.com/sites/docs/sass-mixins.html#css-triangle
//    */

// @mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
//     content: '';
//     display: block;
//     width: 0;
//     height: 0;
//     border: inset $triangle-size;
//     @if ($triangle-direction==down) {
//         border-color: $triangle-color transparent transparent;
//         border-top-style: solid;
//         border-bottom-width: 0;
//     }
//     @if ($triangle-direction==up) {
//         border-color: transparent transparent $triangle-color;
//         border-bottom-style: solid;
//         border-top-width: 0;
//     }
//     @if ($triangle-direction==right) {
//         border-color: transparent transparent transparent $triangle-color;
//         border-left-style: solid;
//         border-right-width: 0;
//     }
//     @if ($triangle-direction==left) {
//         border-color: transparent $triangle-color transparent transparent;
//         border-right-style: solid;
//         border-left-width: 0;
//     }
// } // Burger
// @mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
//     $burger-height: $height !global;
//     $burger-gutter: $gutter !global;
//     position: relative;
//     margin-top: $height + $gutter;
//     margin-bottom: $height + $gutter;
//     user-select: none; // 1. Fixes jagged edges in Firefox, see issue #10.
//     &,
//     &::before,
//     &::after {
//         display: block;
//         width: $width;
//         height: $height;
//         background-color: $color;
//         outline: 1px solid transparent; // 1
//         @if $border-radius !=0 {
//             border-radius: $border-radius;
//         }
//         transition-property: background-color,
//         transform;
//         transition-duration: $transition-duration;
//     }
//     &::before,
//     &::after {
//         position: absolute;
//         content: "";
//     }
//     &::before {
//         top: -($height + $gutter);
//     }
//     &::after {
//         top: $height + $gutter;
//     }
// } // Select parts of the burger
// @mixin burger-parts {
//     &,
//     &::before,
//     &::after {
//         @content;
//     }
// }

// @mixin burger-top {
//     &::before {
//         @content;
//     }
// }

// @mixin burger-middle {
//     & {
//         @content;
//     }
// }

// @mixin burger-bottom {
//     &::after {
//         @content;
//     }
// } // Burger animations
// @mixin burger-to-cross($color: auto) {
//     & {
//         background-color: transparent;
//     }
//     @if ($color !=auto) {
//         &::before,
//         &::after {
//             background-color: $color;
//         }
//     }
//     &::before {
//         transform: translateY($burger-gutter + $burger-height) rotate(45deg);
//     }
//     &::after {
//         transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
//     }
// }

@mixin sm-transition($property, $time: $transition-duration) {
    transition: $property $time cubic-bezier(.45, .22, .41, .92);
}

@mixin sm-depth($depth) {
    box-shadow: 0 $depth+px $depth*2+px 0 rgba(0, 0, 0, 0.2);
}

@mixin sm-get-sizes($size) {
    font-size: map-deep-get($f-styles, $size, 'size');
    line-height: map-deep-get($f-styles, $size, 'lineh');
    letter-spacing: map-deep-get($f-styles, $size, 'spacing');
}

@mixin iconfont() {
    font-family: 'kukri' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin make-spacers($gutters: $grid-gutter-widths) {
    @each $breakpoint in map-keys($gutters) {
        @include media-breakpoint-up($breakpoint) {
            $gutter: map-get($gutters, $breakpoint);
            margin-bottom: ($gutter / 2);
        }
    }
}

@mixin noselect() {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently not supported by any browser */
}

@mixin dropdownarrow() {
    position: relative;
    &.active {
        &:after {
            content: $icon-keyboard_arrow_up;
        }
    }
    &:after {
        position: absolute;
        top: 0;
        right: 0;
        content: $icon-keyboard_arrow_down;
        @include iconfont();
    }
}