// Bootstrap Overrides 
// https://getbootstrap.com/docs/4.1/getting-started/theming/

// Colours
$gray-dark: $color-grey-dark1;
// Body
$body-bg: $color-white;
$body-color: $color-primary;

$container-max-widths: (
    sm: 540px,
    md: 720px, 
    lg: 960px, 
    xl: 1480px,
    xxl: 90vw
);

$grid-breakpoints: ( 
    xs: 0, 
    sm: 576px, 
    md: 768px, 
    lg: 992px,
    xl: 1200px,
    xxl: 1600px
);

// Grid
//
// $grid-gutter-width-base: 30px;
// $grid-gutter-width-tablet: 30px;
// $grid-gutter-width-mobile: 30px;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
// $grid-gutter-widths: (
//     xs: $grid-gutter-width-mobile, 
//     sm: $grid-gutter-width-tablet, 
//     md: $grid-gutter-width-tablet,
//     lg: $grid-gutter-width-base,
//     xl: $grid-gutter-width-base
//     );
// $container-gutter-widths: (
//     xs: $grid-gutter-width-mobile,
//     sm: $grid-gutter-width-tablet,
//     md: $grid-gutter-width-tablet,
//     lg: $grid-gutter-width-base,
//     xl: $grid-gutter-width-base) !default;

$font-family-base: 'Camber', Helvetica, sans-serif;
// $font-size-root: 16px !default;
// $font-size-base: 1rem !default;
// $font-size-lg: 1.25rem !default;
// $font-size-sm: .875rem !default;
// $font-size-xs: .75rem !default;
$headings-font-family: 'Camber', Helvetica, sans-serif;
$headings-font-weight: 700;
$border-radius: 3px;

// Forms
$input-disabled-bg: $color-disabled;
$input-color: $color-primary;
$input-btn-border-width: 0;
$input-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
// $input-border-radius: $border-radius;
// $input-border-radius-lg: 0;
// $input-border-radius-sm: 0;
$input-bg-disabled: $color-grey-light2;
$input-padding-x: 0.75rem;
$input-padding-y: 0.625rem;


//$input-height-border: $input-border-width * 2 !default;
//$input-height-border: 2px;

