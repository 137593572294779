.form-control {
    border: 0;
}

input[type="number"],
input[type="search"],
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
select,
textarea {
    font-family: "Camber", Helvetica, Arial, sans-serif;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.24);
    color: $color-primary;
    // padding: 5px 12px 5px 12px;
    padding: 8px 12px 9px 12px;
    &:hover {
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.56);
    }
    &:focus,
    &:active {
        box-shadow: inset 0 -2px 0 0 $color-secondary;
    }
    &:disabled {
        box-shadow: none;
    }
    &.is-error {
        box-shadow: inset 0 -1px 0 0 $color-error;
        &:focus,
        &:active {
            box-shadow: inset 0 -2px 0 0 $color-error;
        }
    }
}

label {
    color: $color-black-t2;
}

.is-label {
    color: $color-black-t2;
    @extend .text_size_caption;
    display: block;
    margin-bottom: 0.5rem;
}

.form-control--desc {
    display: block;
    margin-right: 10px;
    margin-top: 5px;
    color: $color-black-t2;
}

.is-error {
    color: $color-error;
    .form-control--desc {
        font-weight: 600;
        color: $color-error;
    }
}

.form-group {
    position: relative;
    label {
        @extend .text_size_caption;
    }
}

input[type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    // overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

#orderProgressionPage input[type="hidden"]+label:before,
input[type="checkbox"]+label:before {
    content: $icon-check_box_outline_blank;
    margin-right: 6px;
    color: $color-black-t2;
    text-align: center;
    font-family: kukri !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 1px;
    z-index: 1;
}

.custom-checkbox:before {
    content: $icon-check_box_outline_blank;
    margin-right: 6px;
    color: $color-black-t2;
    text-align: center;
    font-family: kukri !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    left: 50%;
    top: 100%;
    z-index: 1;
    margin-left: -12px;
}

.custom-checkbox__left:before {
    content: $icon-check_box_outline_blank;
    margin-right: 6px;
    color: $color-black-t2;
    text-align: center;
    font-family: kukri !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 2px;
    z-index: 1;
    margin-left: -22px;
}

.custom-checkbox__right:before {
    content: $icon-check_box_outline_blank;
    margin-right: 6px;
    color: $color-black-t2;
    text-align: center;
    font-family: kukri !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 2px;
    right: -30px;
    z-index: 1;
}

.custom-checkbox__clone:before {
    content: $icon-check_box_outline_blank;
    margin-right: 6px;
    color: $color-black-t2;
    text-align: center;
    font-family: kukri !important;
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

input[type="checkbox"] {
    &:hover+label,
    &:focus+label {
        color: $color-black-t1;
    }
}

input[type="checkbox"]:checked {
    &:focus+label {
        color: $color-black-t2;
    }
}

#orderProgressionPage input[type="hidden"]+label,
input[type="checkbox"]+label {
    font-size: 1.03rem;
    display: block;
    padding-left: 34px;
    position: relative;
    &:before {
        @include noselect();
    }
}

#orderProgressionPage input[type="checkbox"]:checked~label:before,
input[type="checkbox"]:checked+label:before,
.custom-checkbox:checked:before,
.custom-checkbox__left:checked:before,
.custom-checkbox__clone:checked:before,
.custom-checkbox__right:checked:before {
    content: $icon-check_box;
    color: $color-secondary;
    font-weight: 400;
}

input[type="checkbox"]:checked+label {
    font-weight: 600;
    letter-spacing: 0;
    color: $color-black-t1;
}

input[type="number"] {
    -moz-appearance: textfield;
    margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

.input-number-controls {
    .input-decrease,
    .input-increase {
        border: 0;
        padding: 3px;
        background-color: $color-black;
        height: 100%;
        display: inline-block;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        @include noselect();
        &:after {
            @include iconfont();
            color: $color-white;
            position: relative;
            font-size: 16px;
            line-height: 1;
            width: 16px;
            height: 16px;
            display: block;
        }
        // &:hover,
        &:focus {
            @include sm-depth(2);
        }
        &:active {
            @include sm-depth(8);
        }
    }
    .input-decrease {
        &:after {
            content: $icon-remove;
        }
    }
    .input-increase {
        &:after {
            content: $icon-add;
        }
    }
    input[type="number"] {
        display: inline-block;
        width: 52px;
        margin: 0 5px;
    }
    &--disabled {
        .input-decrease,
        .input-increase {
            background-color: $color-black-t3;
            cursor: not-allowed;
        }
    }
}

input[type="radio"]+label:before {
    content: $icon-radio_button_unchecked;
    margin-right: 6px;
    color: $color-black-t2;
    text-align: center;
    font-family: kukri;
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    left: 0;
    top: 1px;
    z-index: 1;
}

input[type="radio"]+label {
    font-size: 1.03rem;
    display: block;
    padding-left: 34px;
    position: relative;
    -webkit-touch-callout: none; // iOS Safari
    -webkit-user-select: none; // Chrome/Safari/Opera
    -khtml-user-select: none; // Konqueror
    -moz-user-select: none; // Firefox
    -ms-user-select: none; // Internet Explorer/Edge
    user-select: none; // Non-prefixed version
}

input[type="radio"]:checked+label:before {
    content: $icon-radio_button_checked;
    color: $color-secondary;
    font-weight: 400;
}

input[type="radio"]:checked+label {
    font-weight: 600;
    letter-spacing: 0;
    color: $color-black-t1;
}

.checkbox-colour__wrap {
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 52px;
    height: 36px;
    display: block;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;
}

.input-colour {
    margin: 12px 12px 0 0px;
    display: inline-block;
    #color-white+label,
    #primary-color-white+label,
    #secondary-color-white+label {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.88);
        border-radius: 2px;
    }
    input[type="radio"]+label:before,
    input[type="checkbox"]+label:before {
        content: none;
    }
    input[type="radio"]:checked+label:before,
    input[type="checkbox"]:checked+label:before {
        content: $icon-check_box;
    }
    input[type="radio"]:checked+label:after,
    input[type="checkbox"]:checked+label:after {
        content: "";
        display: block;
        width: 17px;
        height: 17px;
        position: absolute;
        top: -10px;
        right: -6px;
        background-color: $color-white;
    }
    .colour--primary,
    .colour--secondary {
        width: 52px;
        height: 36px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
    }
    .colour--secondary {
        transform: rotate(-45deg);
        left: 7px;
        top: 10px;
        width: 70px;
    }
    .input-colour__label {
        width: 52px;
        height: 36px;
        padding: 0;
        display: inline-block;
        margin: 0;
        &:before {
            top: -10px;
            right: -15px;
            left: auto;
        }
    }
}

.input-displaylimit {
    @include sm-get-sizes("small");
    position: absolute;
    top: 11px;
    right: 8px;
    color: $color-black-t2;
    @include noselect;
}

.has-displaylimit {
    .form-control {
        padding-right: 50px;
    }
}

.custom-file {
    .custom-file-control {
        background: none;
        border: 0;
        padding: 0;
        &:after {
            content: "Choose a file";
            @extend .btn;
            background-color: $color-secondary;
        }
    }
    .custom-supporting-control {
        &:after {
            content: "Choose Supporting File";
        }
    }
}

input.logo-upload {
    display: none;
}

.input-colour {
    margin: 10px 10px 0 0;
    display: inline-block;
    input[type="radio"] {
        display: none !important;
    }
}

.input_hidden {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-check {
    &_address-select {
        padding: 15px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        background: #f0f0f0;
        &--checked {
            background: #fff;
        }
    }
}

.mat-tooltip {
    white-space: pre-line;
}
