.custom-dialog-container, .mat-dialog-container, .cdk-overlay-container {
  z-index: 9999 !important;
  display: flex;
}

.mat-dialog-content {
  max-height: 75vh !important;
  flex: 1;
}

.mat-chip-product-add {
  color: white !important;
  background-color: black !important;
  font-size: 12px !important;
}

.product-search-item {
  display: inline-block;
  vertical-align: top;
}

.hidetext { -webkit-text-security: disc;}

virtual-scroller {
  width: 100%;
  height: 54vh;
}