.product-filtered-grid {
    .show-results-wrap {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        z-index: 99999;
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    &__count {
        text-align: center;
        display: block;
        position: relative;
        @extend .text_size_caption;
        @include media-breakpoint-up(lg) {
            text-align: left;
            float: left;
            top: 9px;
        }
    }
    &__view {
        display: block;
        float: right;
        &__text {
            position: relative;
            bottom: 13px;
            right: 10px;
        }
        &--three,
        &--four {
            display: inline-block;
            width: 98px;
            height: 40px;
            padding: 8px;
            margin-right: 16px;
            @include clearfix();
            box-shadow: 0 0 0 1px $color-black-t3;
            &.active {
                box-shadow: 0 0 0 1px $color-black-t2;
                span {
                    background-color: $color-black-t2;
                }
            }
            span {
                display: block;
                background-color: $color-black-t3;
                height: 100%;
                float: left;
                margin-right: 8px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &--three {
            span {
                width: 22px;
            }
        }
        &--four {
            span {
                width: 14px;
            }
        }
    }
    // &__sort {
    //     text-align: right;
    //     @include clearfix();
    //     &__sortby {
    //         position: relative;
    //         bottom: 13px;
    //         right: 10px;
    //     }
    //     &__dropdown {
    //         display: inline-block;
    //         width: 130px;
    //     }
    // }
}

.price-limit__min,
.price-limit__max {
    width: 50%;
    display: block;
    float: left;
}

.price-limit__min {
    padding-right: 10px;
}

.price-limit__max {
    padding-left: 10px;
}

.mobile-filters-tab {
    @include sm-get-sizes("subheader");
    font-weight: $text_weight_ultrabold;
    @include clearfix();
    &__filter,
    &__sort {
        background-color: $color-white;
        width: calc(50% - 8px);
        float: left;
        padding: 10px;
        text-align: center;
        margin-bottom: 15px;
        @include dropdownarrow();
        &:after {
            top: 9px;
            right: 8px;
        }
        &.active {
            &:before {
                content: "";
                display: block;
                background-color: $color-white;
                width: 100%;
                height: 20px;
                position: absolute;
                left: 0;
                bottom: -20px;
            }
        }
    }
    &__sort {
        margin-left: 16px;
    }
    .default-color-active {
        color: $color-active;
         &:hover,
        &:focus {
            color: rgba($color-active, 0.9);
            outline: none;
            text-decoration: none;
        }
    }
    @extend .links-black-t1;
}

@media (min-width: 992px) {
    .mobile-filters-tab {
        display: none;
    }
}

.mobile-filters-content {
    &__filter,
    &__sort {
        display: none;
        &.active {
            display: block !important; // TODO: Workaround for now, needs a complete refactor
        }
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

.filter {
    background-color: $color-white;
    margin: 2px 0;
    padding: 20px 18px;
    position: relative;
    @include media-breakpoint-up(lg) {
        margin: 15px 0;
        // padding: 20px 18px 20px 0;
        // &:after {
        //     content: "";
        //     display: block;
        //     height: 100%;
        //     width: 200%;
        //     position: absolute;
        //     top: 0;
        //     left: -200%;
        //     background-color: $color-white;
        //     z-index: -1;
        // }
    }
    &__header {
        display: none;
        @extend .text_size_title;
        font-weight: $text_weight_ultrabold;
        color: $color-black-t2;
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
    &__title {
        @extend .text_size_subheader;
        @extend .text_weight_medium;
        display: block;
        color: $color-black;
        padding-right: 26px;
        @include dropdownarrow();
    }
    &__title--static {
        @extend .text_size_subheader;
        @extend .text_weight_medium;
        display: block;
        color: $color-black;
        padding-right: 26px;
    }
    &__content {
        display: none;
        @include clearfix();
        &.active {
            display: block;
        }

        &.active:not(.account__filter) {
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        >ul {
            margin-top: 20px;
            &.filter--more-container {
                margin-top: 0;
                display: none;
                &.active {
                    display: block;
                }
            }
        }
        .form-group {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &--more-link {
        margin-top: 8px;
        display: inline-block;
    }
    &--amount {
        position: absolute;
        top: 1px;
        right: 6px;
    }
    &--list-amount {
        input[type="checkbox"]+label {
            padding-right: 32px;
            .filter--amount {
                font-weight: $text_weight_regular;
                @include sm-get-sizes("small");
            }
        }
    }
    &--list-size {
        @include clearfix();
        margin-right: -18px;
        li {
            display: block;
            float: left;
            width: 33%;
            padding-right: 18px;
        }
        input[type="checkbox"]:checked {
            &:focus+label {
                background-color: rgba($color-active, 0.7);
            }
        }
        input[type="checkbox"]+label {
            font-size: 14px;
            border: 1px solid $color-black-t1;
            border-radius: $border-radius;
            padding: 8px;
            text-align: center;
            &:before {
                content: none;
            }
        }
        input[type="checkbox"]:checked+label {
            background-color: $color-secondary;
            color: $color-white;
            border: 1px solid $color-secondary;
        }
    }
    &--list-colour {
        @include clearfix();
        margin-right: -18px;
        li {
            display: block;
            float: left;
            width: 25%;
            padding-right: 18px;
        }
        .input-colour {
            margin: 0;
            display: block;
            &__label,
            .checkbox-colour__wrap,
            .colour--primary {
                width: 100%;
            }
        }
    }
    &__selection {
        li {
            font-weight: $text_weight_ultrabold;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &--remove {
            // &:hover,
            &:focus {
                &:before {
                    text-shadow: rgba(0, 0, 0, 0.2) 0 0 4px;
                }
            }
            &:active {
                &:before {
                    text-shadow: rgba(0, 0, 0, 0.4) 0 0 6px;
                }
            }
            &:before {
                font-size: 22px;
                color: $color-black;
                content: $icon-cancel;
                @include iconfont();
                position: relative;
                top: 3px;
                margin-right: 8px;
            }
        }
    }
}

#filter-sortby {
    &__default,
    &__relevance,
    &__price,
    &__date {
        display: none;
    }
}


.filter {
    &__form--uk {
        position: absolute;
        z-index: 100;
    }
    &__sort--uk {
        position: absolute;
        z-index: 100;
        margin: 0;
        right: 15px;
        width: calc(50% - 22px);
    }
    &--uk {
        margin: 0 !important;
    }
    &__header--uk {
        display: none !important;
    }
}