// For resetting relative positioning on grid columns
.no-positioning {
    position: static;
} // Restrict images to column width
div [class*=' col-'] img {
    max-width: 100%;
} // 10px gutter
.gutter-sm.row {
    margin-right: -8px;
    margin-left: -8px;
}

.gutter-sm>[class^="col-"],
.gutter-sm>[class^=" col-"] {
    padding-right: 8px;
    padding-left: 8px;
}

.container-negative {
    margin-left: -16px;
    margin-right: -16px;
    @include media-breakpoint-up(sm) {
        margin-left: -30px;
        margin-right: -30px;
    }
} // TODO: Mixin does not exist...
// .container, .container-fluid, .mobile-menu {
//   @include make-gutters($container-gutter-widths);
// }
// ----------------------------------------------
// Set margin bottom to columns
//
//    USAGE:
//
//    <div class="row margin-col-lg">
//      <div class="col-lg-4"></div>
//    </div>
//
// Set margin bottom when column break to one line
// ----------------------------------------------
//.margin-col {
//  > div[class*="col-"], > div[class*=" col-"] {
//    @include make-spacers($container-gutter-widths);
//  }
//}
//@each $breakpoint in map-keys($grid-breakpoints) {
//  .margin-col-#{$breakpoint} {
//    @include media-breakpoint-down($breakpoint) {
//
//      > div[class*="col-"], > div[class*=" col-"] {
//        @include make-spacers($container-gutter-widths);
//        &:last-child {
//          margin-bottom: 0;
//        }
//    &:first-child {
//        @include make-spacers($container-gutter-widths);
//  }
//      }
//  }
// }
//}
.margin-col-big {
    >div[class*="col-"],
    >div[class*=" col-"] {
        margin-bottom: $spacer * 3;
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    .margin-col-big-#{$breakpoint} {
        @include media-breakpoint-down($breakpoint) {
            >div[class*="col-"],
            >div[class*=" col-"] {
                margin-bottom: $spacer * 3;
                &:last-child {
                    margin-bottom: 0;
                }
                &:first-child {
                    margin-bottom: $spacer * 3;
                }
            }
        }
    }
}

.no-gutter.container,
.no-gutter.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.no-gutter.row {
    margin-right: 0;
    margin-left: 0;
    &>[class^="col-"],
    &>[class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.row.is-flex {
    @include media-breakpoint-up(sm) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        > [class*='col-'] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }
    }
}

#filter-products {
    justify-content: space-evenly;
}