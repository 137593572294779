.product-slider {
    &__thumbs {
        .slick-prev,
        .slick-next {
            &:before {
                font-weight: $text_weight_ultrabold;
                font-size: 22px;
            }
        }
        .slick-prev {
            top: 0;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, 0);
            &:before {
                content: $icon-keyboard_arrow_up;
            }
        }
        .slick-next {
            bottom: 0;
            top: auto;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, 0);
            &:before {
                content: $icon-keyboard_arrow_down;
            }
        }
        .slick-slide {
            img {
                // padding: 6px 3px;
                margin: 0 10px 0 0;
            }
            @include media-breakpoint-up(md) {
                img {
                    margin: 0 0 10px;
                }
            }
        }
        &__wrap {
            position: relative;
            &:before,
            &:after {
                content: none;
            }
            // @include media-breakpoint-up(md) {
            //     &:before {
            //         content: "";
            //         display: block;
            //         background-color: $color-grey-light2;
            //         width: 100%;
            //         height: 30px;
            //         position: absolute;
            //         top: 0;
            //         left: 0;
            //         z-index: 1;
            //     }
            //     &:after {
            //         content: "";
            //         display: block;
            //         background-color: $color-grey-light2;
            //         width: 100%;
            //         height: 30px;
            //         position: absolute;
            //         bottom: 0;
            //         left: 0;
            //         z-index: 1;
            //     }
            // }
        }
    }
}

.row-fluid,
.slick-slider__design-products,
.slick-slider__stock-products {
    @include media-breakpoint-up(sm) {
        .slick-prev {
            left: -54px;
        }
        .slick-next {
            right: -54px;
        }
        .slick-prev,
        .slick-next {
            width: auto !important;
            height: auto !important;
            &::before {
                font-size: 5.5rem;
                color: $color-secondary;
            }
        }
    }
}

//.row-fluid {
//    overflow-y: auto;
//    padding: 25px 5px;
//}
// _slider.scss
.product-details .product-gallery {
    margin-bottom: 0 !important;
}

// MJ - Convert to use SASS Medie Breakpoint
.slick-dotted.slick-slider {
    margin-bottom: 10px;
}

// _variables.scss
@media (min-width: 544px) {
    .slick-dotted.slick-slider {
        margin-bottom: 24px;
    }
}

.slick-dots {
    bottom: 30px !important;
    li button {
        &:before {
            font-size: 70px !important;
        }
    }
}

.slick-slide img {
    width: 100%;
}

.product-slider__thumbs {
    .slick-slide {
        transition: 0.35s ease;
        opacity: 0.75 !important;
        cursor: pointer;
        border: 0 !important;
        &:hover {
            opacity: 1 !important;
        }
    }
    .slick-current {
        opacity: 1 !important;
    }
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 0;
}

@media (min-width: 768px) {
    .product-slider__thumbs__wrap {
        &:before,
        &:after {
            background-color: transparent !important;
        }
    }
}

@media (min-width: 768px) {
    .product-slider__thumbs .slick-slide img {
        max-width: 100px;
        margin: 0 auto 10px !important;
        max-width: 100px;
        float: right;
    }
}

.slick-dots__bottom {
    .slick-dots {
        bottom: -30px !important;
    }
}

.slick-next:before,
.slick-prev:before {
    font-size: 60px;
    color: #00a695;
}

.slick-next,
.slick-prev {
    height: 60px;
    width: 60px;
    z-index: 1000;
}

.slick-next:not(.adjust) {
    right: 15px;
}

.slick-prev:not(.adjust) {
    left: 15px;
}

[id^="tooltip"] {
    z-index: 9999 !important;
}