.icon-info:before {
    font-size: 22px;
    vertical-align: middle;
}

.icon-product-feature {
    img {
        padding: 0 20px 15px;
        border-bottom: 1px solid $color-divider;
        margin: 0 auto 10px;
    }
    p {
        font-size: 14px;
        letter-spacing: 0.4px;
        font-family: "camber";
        line-height: 1.5;
    }
}

.has-icon {
    &:before,
    &:after {
        @include iconfont();
    }
}

.icon-keyboard_arrow_left:before,
.icon-keyboard_arrow_right:before,
.icon-keyboard_arrow_up:before,
.icon-keyboard_arrow_down:before {
    font-size: 2rem;
    display: inline-block;
    vertical-align: middle;
}

span.icon-info {
    position: absolute;
    left: 203px;
    top: 13px;
    z-index: 100000;
    font-size: 1.5rem;
}

#product-detail-tab-content,
#size-guide-modal,
#size-guide,
#saveShopProductVariation {
    .icon-keyboard_arrow_down,
    .icon-keyboard_arrow_up,
    .icon-keyboard_arrow_right,
    .icon-keyboard_arrow_left {
        position: absolute;
        right: 0px;
        top: 15px;
        transition: all 0.3s ease;
        font-size: 22px;
        color: $color-grey-dark1;
        &:before {
            font-size: 24px;
        }
    }
    .icon-keyboard_arrow_right {
        right: 0px;
    }
    .icon-keyboard_arrow_left {
        right: 30px;
    }
    [aria-expanded] {
        cursor: pointer;
    }
    [aria-expanded="true"] {
        .icon-keyboard_arrow_down {
            transform: rotate(-180deg);
        }
    }
}

.icon-small {
    font-size: 24px !important;
}

.icon-medium {
    font-size: 24px;
}

.icon-info:before {
    // content: "";
    font-size: 22px;
    vertical-align: middle;
}

.icon-product-feature {
    img {
        padding: 0 20px 15px;
        border-bottom: 1px solid $color-divider;
        margin: 0 auto 10px;
    }
    p {
        font-size: 14px;
        letter-spacing: 0.4px;
        font-family: "camber";
        line-height: 1.5;
    }
}

span.icon-info {
    position: relative !important;
    font-size: 1.5rem !important;
    left: auto !important;
    top: auto !important;
    z-index: auto !important;
}

.icon-error {
    &.color-red {
        color: $color-error;
        font-size: 45px;
        margin: 15px 0;
        display: inline-block;
    }
}