.order-progress {
    .border {
        border-bottom: 1px solid #b6b6b6;
        padding-right: 30px;
    }
    .panel {
        margin-top: 20px;
        margin-bottom: 20px;
        transition: all 0.5s ease;
        background-color: #fff;
    }
    .card-header {
        background-color: #fff !important;
    }
    .panel-group .panel {
        border-radius: 3px;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .panel-group .panel+.panel {
        margin-top: 20px;
    }
    .panel-default {
        border-color: $color-white;
    }
    .stepTitle {
        color: #a71732; //TODO: This value is not included in the Brand Guidelines
    }
    .stepTitle i {
        color: #a71732;
        padding-right: 5px;
    }
    .panel-default>.panel-heading {
        background-color: $color-white;
    }
    .panel-heading {
        display: block;
        padding: 30px;
        font-size: 24px;
        font-weight: 400;
        h2 {
            font-size: 24px;
            margin-top: 0px;
            text-transform: none;
            font-weight: 400;
        }
    }
    .orderProgressStep {
        color: #212121;
    }
    .orderProgressionPage {
        font-size: 18px;
        min-height: 70vh;
    }
    .orderProgressionPage h1.title {
        padding-top: 10px;
    }
    .orderProgressionPage .button {
        display: inline-block !important;
        min-width: 180px;
        font-size: 18px !important;
        font-weight: 400 !important;
        margin: 0 !important;
        padding: 0 !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 18px !important;
        padding-right: 18px !important;
        text-transform: none !important;
        border: 1px solid #212121 !important;
        background-color: transparent !important;
        color: #212121;
        border-radius: 3px !important;
    }
    .orderProgressionPage .button.requestAmends {
        background-color: transparent !important;
        border-color: #727272 !important;
        color: #727272 !important;
    }
    .orderProgressionPage .button.nextStep {
        background-color: #212121 !important;
        color: $color-white !important;
    }
    .orderProgressionPage input.button.requestAmends {
        margin-right: 15px;
    }
    .orderProgressionPage a {
        color: #a71732;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .orderProgressionPage a:hover {
        opacity: 0.7;
        font-weight: normal !important;
        transition: all 0.5s ease;
    }
    .orderProgressionPage a.link-not-red {
        color: #444443;
    }
    .orderProgressionPage a.link-not-red:hover {
        color: #a71732;
        opacity: 1;
    }
    .orderProgressionPage input[type="checkbox"] {
        -webkit-appearance: none;
        background-color: transparent !important;
        border: none !important;
    }
    .orderProgressionPage input[type="checkbox"]:before {
        font-family: FontAwesome;
        display: inline-block;
        font-size: 36px;
        margin-left: 2px;
        content: "\f096";
        color: #d4d4d4;
        opacity: 0.7;
    }
    .orderProgressionPage input[type="checkbox"]:checked:before {
        content: "\f046";
        font-size: 36px;
        margin-left: 2px;
        color: #000;
        opacity: 1;
    }
    .orderProgressionPage textarea {
        width: 100%;
        font-size: 24px;
        font-weight: 400;
        border: none;
        background-color: #ebebeb !important;
        margin-top: 10px;
        margin-bottom: 5px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .stepButtons {
        text-align: right;
    }
    .requestAmendsButton {
        margin-right: 20px !important;
    }
    #step2 .nextStepButton {
        margin-right: 20px !important;
    }
    .totalPrice {
        font-weight: 600;
    }
    .termsConditions {
        text-align: left;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .termsConditionsSentence {
        padding-left: 45px;
        margin-top: -37px;
    }
    .customise-checkbox {
        input[type="checkbox"] {
            clip: inherit !important;
        }
    }
    .quoteSummary {
        .border {
            border-bottom: 1px solid #b6b6b6;
            padding-right: 30px;
            i {
                padding-right: 10px;
            }
            h3 {
                font-size: 24px;
                font-weight: 400;
                text-transform: none;
                margin-top: 60px;
                margin-bottom: 0px !important;
            }
        }
        .row-spacing {
            margin-bottom: 20px;
        }
    }
    .contactInfo {
        border-top: 1px solid #b6b6b6;
        padding-top: 30px;
        margin-top: 10px;
        color: #444444;
        font-weight: 400;
        i {
            padding-right: 15px;
        }
        p {
            padding-bottom: 10px;
        }
    }
}

#orderProgressionPage .show {
    display: block;
}

#orderProgressionPage .hide {
    display: none;
}