$icomoon-font-family: "kukri" !default;
$icomoon-font-path: "../../fonts/icomoon-new" !default;

$icon-attach_file: "\e900";
$icon-print: "\e90a";
$icon-room: "\e901";
$icon-lock_outline: "\e902";
$icon-mail_outline: "\e903";
$icon-new_releases: "\e904";
$icon-notifications: "\e905";
$icon-panorama: "\e906";
$icon-person: "\e907";
$icon-settings: "\e908";
$icon-share: "\e909";
$icon-add: "\e90f";
$icon-arrow_back: "\e910";
$icon-border_color: "\e911";
$icon-brush: "\e912";
$icon-build: "\e913";
$icon-cancel: "\e914";
$icon-check: "\e915";
$icon-check_box: "\e916";
$icon-check_box_outline_blank: "\e917";
$icon-close: "\e918";
$icon-palette: "\e919";
$icon-content_copy: "\e91a";
$icon-mode_edit: "\e91b";
$icon-date_range: "\e91c";
$icon-delete: "\e91d";
$icon-equalizer: "\e91e";
$icon-error: "\e91f";
$icon-favorite: "\e920";
$icon-favorite_border: "\e921";
$icon-format_color_fill: "\e922";
$icon-format_color_text: "\e923";
$icon-home: "\e924";
$icon-indeterminate_check_box: "\e925";
$icon-info: "\e926";
$icon-keyboard_arrow_down: "\e927";
$icon-keyboard_arrow_left: "\e928";
$icon-keyboard_arrow_right: "\e929";
$icon-keyboard_arrow_up: "\e92a";
$icon-local_shipping: "\e92b";
$icon-menu: "\e92c";
$icon-more_horiz: "\e92d";
$icon-more_vert: "\e92e";
$icon-notifications1: "\e92f";
$icon-radio_button_unchecked: "\e930";
$icon-person1: "\e931";
$icon-radio_button_checked: "\e932";
$icon-refresh: "\e933";
$icon-remove: "\e934";
$icon-replay: "\e935";
$icon-save: "\e936";
$icon-search: "\e937";
$icon-send: "\e938";
$icon-shopping_basket: "\e939";
$icon-star_border: "\e93a";
$icon-translate: "\e93b";
$icon-american-express: "\e93c";
$icon-mastercard-alt: "\e93d";
$icon-paypal: "\e93e";
$icon-stripe: "\e93f";
$icon-visa: "\e940";
$icon-apple-pay: "\e941";
$icon-sage: "\e942";
$icon-alipay: "\e943";
$icon-google: "\ea88";
$icon-facebook: "\ea90";
$icon-instagram: "\ea92";
$icon-twitter: "\ea96";
$icon-youtube: "\ea9d";
$icon-linkedin: "\eac9";
