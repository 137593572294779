.card {
    background-color: white;
    border: transparent;
}

.card-header {
    background: #fafafa;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    h5 {
        font-size: 16px;
        letter-spacing: 0;
        color: rgba(0, 0, 0, 0.56);
        font-weight: 500;
        font-size: 18px;
        line-height: 1.35;
    }
}

.accordion__icon--menu {
    position: absolute;
    left: 15px;
    top: 18px;
    transition: all 0.3s ease;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.875);
}

.icon-keyboard_arrow {
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all 0.3s ease;
    font-size: 22px;
    color: white;
}

.ml-45px {
    margin-left: 45px;
}

.dark-theme {
    .card {
        background-color: #f0f0f5;
        border: transparent;
        color: #fff;
        a {
            color: #3c3c3c;
        }
    }
    .card-header {
        background: #141414 !important;
        color: #fff;
        border-top: 1px solid rgba(255, 255, 255, 0.175);
        border-radius: 0;
        h5 {
            color: #fff !important;
        }
        &:first-child {
            border-radius: 0;
        }
    }
    .card-body {
        background: #f0f0f5;
    }
}

.shop-admin-menu-wrapper {
    min-height: 100%;
    background: #f0f0f5;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}