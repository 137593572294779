.main-content {
    .section {
        margin-bottom: 30px;
        margin-top: 60px;
        @include media-breakpoint-up(md) {
            margin-bottom: 60px;
            margin-top: 60px;
        }
    }
}

.page-content {
    // margin-top: 75px;
    margin-bottom: 48px;
    // @include media-breakpoint-up(lg) {
    //     margin-top: 24px;
    // }
    // &__no-title {
    //     margin-top: 24px;
    // }
}

.page-title {
    &__wrap {
        margin-bottom: 60px;
    }
}

.section-title {
    @extend .text_size_headline;
    @extend .text_weight_medium;
    color: $color-black-t2;
    margin-bottom: 24px;
}

.product-grid {
    >div {
        &:last-child,
        &:nth-last-child(2) {
            margin-bottom: 0;
        }
    }
}

// .undercheckout {
//     font-weight: 400;
//     @extend .links-secondary;
//     background-color: $color-grey-dark2;
//     padding: 32px;
//     text-align: center;
//     color: $color-white;
//     margin: 0;
//     border-radius: $border-radius;
//     @include media-breakpoint-up(xs) {
//         margin: 0px -16px;
//     }
//     @include media-breakpoint-up(sm) {
//         margin: 0px -32px;
//     }
//     @include media-breakpoint-up(md) {
//         margin: 0;
//         font-weight: 600;
//     }
//     p {
//         max-width: 200px;
//         margin: auto;
//         margin-bottom: 0.5rem;
//     }
// }
// .sidebar {
//     >div {
//         &:last-child {
//             margin-bottom: 0;
//         }
//     }
// }

@media (max-width: 991px) {
    .mt-60 {
        margin-top: 60px;
    }
}