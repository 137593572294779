@font-face{
	font-family:"Loughborough";
	src:url("../fonts/lboro/90744ee6-df8b-4daf-924d-e84a33fa139c.eot?#iefix");
	src:url("../fonts/lboro/90744ee6-df8b-4daf-924d-e84a33fa139c.eot?#iefix") format("eot"),
	url("../fonts/lboro/398e3e8c-3bf0-4af1-9791-f426a7992711.woff2") format("woff2"),
	url("../fonts/lboro/4ba8e512-e6fb-494f-afd3-a7b68b2e5efb.woff") format("woff"),
	url("../fonts/lboro/d988fb64-309d-4c7f-9ded-4e9663aa6061.ttf") format("truetype");
}
@font-face{
    font-family:"LoughboroughBold";
    src:url("../fonts/lboro/9cab3d10-a1a9-4145-ab8a-cde6265a011d.eot?#iefix");
    src:url("../fonts/lboro/9cab3d10-a1a9-4145-ab8a-cde6265a011d.eot?#iefix") format("eot"),
    url("../fonts/lboro/55fa1cf8-baf8-4bf8-9718-b096b1dd231f.woff2") format("woff2"),
    url("../fonts/lboro/4cadc2f9-fb32-4b99-b1f2-9ce6d68afbd5.woff") format("woff"),
    url("../fonts/lboro/24018d68-3235-4323-9ec0-4a86537b0f30.ttf") format("truetype");
}
strong.loughborough-normal {
    font-family: "Loughborough";
}
strong.loughborough-bold {
    font-family: "LoughboroughBold";
}
