
.shop-item {
    position: relative;
    display: block;
    height: 100%;
    &__image-wrap {
        position: relative;
        overflow: hidden; // Prevents images expanding outside of container when zooming in on hover
        background: #f0f0f0; // TODO: Change to SASS Variable
    }
    image-wrap-choices {
        position: relative;
        overflow: hidden; // Prevents images expanding outside of container when zooming in on hover
        background: #f0f0f0; // TODO: Change to SASS Variable
        height: 10%;
        width: auto;
    }
    &__zoom-on-hover {
        transform: scale(1.005);
        transition: transform .6s;
        &:hover {
            transform: scale(1.06);
        }
    }
    &__image {
        //border-top-left-radius: $border-radius;
        //border-top-right-radius: $border-radius;
        background: #f0f0f0; // $color-grey-light3
        // position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
    &__image-logo {
        position: absolute;
        z-index: 1;
        max-width: 32px !important;
        height: auto;
        max-height: 32px;
        margin-left: -16px;
        margin-top: -16px;
    }
    &__svg {
        svg {
            position: absolute !important;
            left: 0;
            top: 0;
            z-index: 1;
        }
    }
    &_placeholder {
        z-index: 1;
    }
    &__placeholder--landing-page {
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    .card-body__bg--white {
        background: white;
    }
    &__info-wrap {
        position: relative;
    }
    &__info {
        position: relative;
        border-top: 1px solid $color-divider;
        bottom: auto;
        color: $color-black-t2;
        background-color: $color-white-t0;
        //-webkit-backdrop-filter: blur(4px);
        padding: 8px 10px;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        font-weight: 500;
        text-align: center;
        &:before {
            @include iconfont();
            content: $icon-palette;
            font-size: 16px;
            line-height: 1;
        }
    }

    .ribbon-wrapper {
        width: 85px;
        height: 88px;
        overflow: hidden;
        position: absolute;
        top: -3px;
        left: -3px;
        .ribbon {
            font: bold 15px sans-serif;
            color: #dbbc1e;
            text-align: center;
            -webkit-transform: rotate(-45deg);
            -moz-transform:    rotate(-45deg);
            -ms-transform:     rotate(-45deg);
            -o-transform:      rotate(-45deg);
            position: relative;
            padding: 7px 0;
            top: 15px;
            left: -30px;
            width: 120px;
            background-color: #1eb5ff;
            color: #fff;
        }
    }

    .sale-ribbon{
        display: inline-block;
        padding: 2px 15px;
        position: absolute;
        right: 0px;
        top: 20px;
        line-height: 24px;
        height:24px;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
        border-radius: 0;
        text-shadow: none;
        font-weight: normal;
        background-color: #1eb5ff !important;
    }

    .sale-ribbon-round {
        background: #1eb5ff;
        right: 10px;
        top: 10px;
        padding: 0px;
        height: 60px;
        width: 60px;
        text-align: center;
        vertical-align: middle;
        line-height: 60px;
        border-radius:50%;
    }

    .badge-new,
    .badge-off {
        position: absolute;
        color: $color-white;
        text-align: right;
        padding: 5px 12px 5px 5px;
        font-size: 14px;
        font-weight: $text_weight_ultrabold;
        bottom: 16px;
        right: 0;
        min-width: 80px;
        z-index: 1;
    }

    &__choices {
        height: 8rem;
        width: auto;
        margin-bottom: 1%;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
    }

    &__details {
        padding: 15px;
        position: relative;
        background-color: $color-white;
        &__title {
            @extend .text_size_subheader;
            color: $color-black-t1;
            font-weight: $text_weight_bold;
            font-size: 16px;
            line-height: 1.25;
        }
        &__desc {
            @extend .text_size_caption;
            color: $color-black-t2;
            margin-bottom: 5px;
        }
        &__price {
            font-size: 32px;
            color: $color-secondary;
            font-weight: $text_weight_ultrabold;
            del .price-display {
                font-size: 14px;
                font-weight: 500;
            }
            ins .price-display {
                font-size: 16px;
                font-weight: 500;
            }
        }

        &:hover {
            span {
                b {
                    opacity: 0.6;
                }
            }
        }
    }
    .badge-new {
        background-color: $color-secondary;
    }
    .badge-off {
        background-color: $color-error;
    }

    .group_label {
        width: inherit !important;
        text-align: center;
        margin: 60px 0 10px 0;
    }

    &__grouped_products_wrap {
        display: none;
        flex-wrap: wrap;
        justify-content: center;
        width: 70vw;
        position: relative;
        z-index: 2;
        cursor: default;
    }

    &__grouped_products {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        max-height: 600px;
        overflow: auto;
        z-index: 3;
        cursor: default;

        .ribbon-wrapper {
            .ribbon {
                top: 5px;
                left: -37px;
                font-size: 12px;
            }
        }

        .sale-ribbon-round {
            height: 40px;
            width: 40px;
            line-height: 40px;
            font-size: 12px;
        }

        [id^=productThumbnail-] {

            pointer-events: auto;

            &:hover {
                opacity: 0.6;
            }

            small:not(.fromPriceLit),
            b {
                width: 100%;
                display: block;
                text-align: center;
                margin: 5px 0 5px 0;
            }

            [id^=productImageLogoCanvas-] {
                max-height: calc(70vw * 22/100);
            }
        }
    }
}

.image-wrap-choices {
    position: relative;
    background: #f0f0f0; // TODO: Change to SASS Variable
    height: 6rem;
    width: 5.5rem;
    max-height: 6rem;
}

.choice-img{
    height: 4rem;
    width: 4rem;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.choice-svg{
    height: 6rem;
    width: auto;
}

[class*= left_], [class*= wrap_] {
    display: flex;
    margin-bottom: 40px;
}

.full_wrap {
    width: 85vw !important;
}


.left_first {
    left: 0;
}

.left_3_second {
    left: calc(33.3% - 32.2vw);
}

.left_3_third {
    left: calc(33.3% - 56.9vw);
}

.left_4_second {
    left: calc(33.3% - 24vw);
}

.left_4_third {
    left: calc(33.3% - 42.5vw);
}

.left_4_fourth {
    left: calc(33.3% - 61vw);
}



.left_3_minus_last {
    left: calc(33.3% - 50vw);
}

.left_3_minus_last_but_one {
    left: calc(33.3% - 16vw);
}


.left_4_minus_last {
    left: calc(33.3% - 50vw);
}

.left_4_minus_last_but_one {
    left: calc(33.3% - 21vw);
}

.left_4_minus_last_but_two {
    left: calc(33.3% - 12vw);
}



.left_3_minus_last_a {
    left: calc(33.3% - 32vw);
}

.left_3_minus_last_b {
    left: calc(33.3% - 48.9vw);
}

.left_3_minus_last_but_one_b {
    left: calc(33.3% - 16vw);
}


.left_4_minus_last_a {
    left: calc(33.3% - 33vw);
}



.left_4_minus_last_b {
    left: calc(33.3% - 48.9vw);
}

.left_4_minus_last_but_one_b {
    left: calc(33.3% - 18vw);
}



.left_4_minus_last_c {
    left: calc(33.3% - 56vw);
}

.left_4_minus_last_but_one_c {
    left: calc(33.3% - 33vw);
}

.left_4_minus_last_but_two_c {
    left: calc(33.3% - 10vw);
}



.left_4_minus_last_d {
    left: calc(33.3% - 56vw);
}

.left_4_minus_last_but_one_d {
    left: calc(33.3% - 56vw);
}

.left_4_minus_last_but_two_d {
    left: calc(33.3% - 56vw);
}


.full_wrap.left_4_second {
    left: calc(33.3% - 26vw);
}

.full_wrap.left_4_third {
    left: calc(33.3% - 46vw);
}

.full_wrap.left_4_fourth {
    left: calc(33.3% - 67vw);
}

.full_wrap.left_4_minus_last {
    left: calc(33.3% - 53vw);
}

.full_wrap.left_4_minus_last_a {
    left: calc(33.3% - 36vw);
}

.full_wrap.left_4_minus_last_b {
    left: calc(33.3% - 53vw);
}

.full_wrap.left_4_minus_last_c {
    left: calc(33.3% - 62vw);
}

.full_wrap.left_4_minus_last_but_one {
    left: calc(33.3% - 19vw);
}

.full_wrap.left_4_minus_last_but_one_b {
    left: calc(33.3% - 19vw);
}

.full_wrap.left_4_minus_last_but_one_c {
    left: calc(33.3% - 36vw);
}

.full_wrap.left_4_minus_last_but_two {
    left: calc(33.3% - 12vw);
}

.full_wrap.left_4_minus_last_but_two_c {
    left: calc(33.3% - 12vw);
}


@media (min-width: 992px) {
    .main-content .section {
        margin-bottom: 30px !important;
        margin-top: 30px !important;
    }
}

div#js-tab-product__personalise {
    width: 100%;
    left: 0;
}

.input-number-controls input[type="number"] {
    display: inline-block;
    width: 52px;
    margin: 0 5px;
    text-align: center;
}

.dropdown-item>img {
    max-width: 48px !important;
    height: auto !important;
    max-height: 48px !important;
    width: auto !important;
    margin: 5px 10px 5px 0;
    margin-right: 10px;
}

// base/_dropdown-buttons.scss
.kukri-scrollable-menu {
    height: auto;
    max-height: 240px !important;
    overflow-x: hidden;
    min-width: 100%;
}

// _list.scss or _base.scss
ul.list-styled {
    list-style: disc;
    margin-left: 15px;
    margin-bottom: 24px;
    li {
        list-style: disc;
        margin-left: 15px;
    }
    ul {
        list-style: disc;
        margin-left: 15px;
        li {
            list-style: disc;
            margin-left: 15px;
        }
    }
}

.price-display_custom {
    color: $color-secondary;
    font-weight: $text_weight_bold;
}

.dropdown-item {
    cursor: pointer;
    &:focus {
        background-color: $color-grey-light2;
    }
}

img.ng-lazyloaded {
    animation: fadein 0.35s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dropdown-menu.kukri-scrollable-menu.show {
    background: #f0f0f0 !important;
}