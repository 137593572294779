.summary-pay-box {
    border-radius: $border-radius;
    padding: 24px;
    background-color: $color-white;
    &__line {
        @include clearfix;
        margin-bottom: 8px;
    }
    &__promocode {
        margin-bottom: 20px;
    }
    &__calc {
        @extend .text_size_subheader;
        color: $color-black-t2;
        .summary-pay-box--right {
            float: right;
            @extend .text_weight_ultrabold;
            color: $color-black;
            @include media-breakpoint-only(lg) {
                display: block;
                float: none;
            }
        }
    }
    &__total {
        @extend .text_size_title;
        @extend .text_weight_ultrabold;
        color: $color-secondary;
        margin-top: 16px;
        margin-bottom: 24px;
        .summary-pay-box--right {
            float: right;
            @include media-breakpoint-only(lg) {
                display: block;
                float: none;
            }
        }
    }
}