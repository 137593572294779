@import "variables";
@font-face {
    font-family: "#{$icomoon-font-family}";
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?pmre99");
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?pmre99#iefix") format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?pmre99") format("woff2"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?pmre99") format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?pmre99") format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?pmre99##{$icomoon-font-family}") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "#{$icomoon-font-family}" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-attach_file {
    &:before {
        content: $icon-attach_file;
    }
}

.icon-print {
    &:before {
        content: $icon-print;
    }
}

.icon-room {
    &:before {
        content: $icon-room;
    }
}

.icon-lock_outline {
    &:before {
        content: $icon-lock_outline;
    }
}

.icon-mail_outline {
    &:before {
        content: $icon-mail_outline;
    }
}

.icon-new_releases {
    &:before {
        content: $icon-new_releases;
    }
}

.icon-notifications {
    &:before {
        content: $icon-notifications;
    }
}

.icon-panorama {
    &:before {
        content: $icon-panorama;
    }
}

.icon-person {
    &:before {
        content: $icon-person;
    }
}

.icon-settings {
    &:before {
        content: $icon-settings;
    }
}

.icon-share {
    &:before {
        content: $icon-share;
    }
}

.icon-add {
    &:before {
        content: $icon-add;
    }
}

.icon-arrow_back {
    &:before {
        content: $icon-arrow_back;
    }
}

.icon-border_color {
    &:before {
        content: $icon-border_color;
    }
}

.icon-brush {
    &:before {
        content: $icon-brush;
    }
}

.icon-build {
    &:before {
        content: $icon-build;
    }
}

.icon-cancel {
    &:before {
        content: $icon-cancel;
    }
}

.icon-check {
    &:before {
        content: $icon-check;
    }
}

.icon-check_box {
    &:before {
        content: $icon-check_box;
    }
}

.icon-check_box_outline_blank {
    &:before {
        content: $icon-check_box_outline_blank;
    }
}

.icon-close {
    &:before {
        content: $icon-close;
    }
}

.icon-palette {
    &:before {
        content: $icon-palette;
    }
}

.icon-content_copy {
    &:before {
        content: $icon-content_copy;
    }
}

.icon-mode_edit {
    &:before {
        content: $icon-mode_edit;
    }
}

.icon-date_range {
    &:before {
        content: $icon-date_range;
    }
}

.icon-delete {
    &:before {
        content: $icon-delete;
    }
}

.icon-equalizer {
    &:before {
        content: $icon-equalizer;
    }
}

.icon-error {
    &:before {
        content: $icon-error;
    }
}

.icon-favorite {
    &:before {
        content: $icon-favorite;
    }
}

.icon-favorite_border {
    &:before {
        content: $icon-favorite_border;
    }
}

.icon-format_color_fill {
    &:before {
        content: $icon-format_color_fill;
    }
}

.icon-format_color_text {
    &:before {
        content: $icon-format_color_text;
    }
}

.icon-home {
    &:before {
        content: $icon-home;
    }
}

.icon-indeterminate_check_box {
    &:before {
        content: $icon-indeterminate_check_box;
    }
}

.icon-info {
    &:before {
        content: $icon-info;
    }
}

.icon-keyboard_arrow_down {
    &:before {
        content: $icon-keyboard_arrow_down;
    }
}

.icon-keyboard_arrow_left {
    &:before {
        content: $icon-keyboard_arrow_left;
    }
}

.icon-keyboard_arrow_right {
    &:before {
        content: $icon-keyboard_arrow_right;
    }
}

.icon-keyboard_arrow_up {
    &:before {
        content: $icon-keyboard_arrow_up;
    }
}

.icon-local_shipping {
    &:before {
        content: $icon-local_shipping;
    }
}

.icon-menu {
    &:before {
        content: $icon-menu;
    }
}

.icon-more_horiz {
    &:before {
        content: $icon-more_horiz;
    }
}

.icon-more_vert {
    &:before {
        content: $icon-more_vert;
    }
}

.icon-notifications1 {
    &:before {
        content: $icon-notifications1;
    }
}

.icon-radio_button_unchecked {
    &:before {
        content: $icon-radio_button_unchecked;
    }
}

.icon-person1 {
    &:before {
        content: $icon-person1;
    }
}

.icon-radio_button_checked {
    &:before {
        content: $icon-radio_button_checked;
    }
}

.icon-refresh {
    &:before {
        content: $icon-refresh;
    }
}

.icon-remove {
    &:before {
        content: $icon-remove;
    }
}

.icon-replay {
    &:before {
        content: $icon-replay;
    }
}

.icon-save {
    &:before {
        content: $icon-save;
    }
}

.icon-search {
    &:before {
        content: $icon-search;
    }
}

.icon-send {
    &:before {
        content: $icon-send;
    }
}

.icon-shopping_basket {
    &:before {
        content: $icon-shopping_basket;
    }
}

.icon-star_border {
    &:before {
        content: $icon-star_border;
    }
}

.icon-translate {
    &:before {
        content: $icon-translate;
    }
}

.icon-american-express {
    &:before {
        content: $icon-american-express;
    }
}

.icon-mastercard-alt {
    &:before {
        content: $icon-mastercard-alt;
    }
}

.icon-paypal {
    &:before {
        content: $icon-paypal;
    }
}

.icon-stripe {
    &:before {
        content: $icon-stripe;
    }
}

.icon-visa {
    &:before {
        content: $icon-visa;
    }
}

.icon-apple-pay {
    &:before {
        content: $icon-apple-pay;
    }
}

.icon-sage {
    &:before {
        content: $icon-sage;
    }
}

.icon-alipay {
    &:before {
        content: $icon-alipay;
    }
}

.icon-google {
    &:before {
        content: $icon-google;
    }
}

.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}

.icon-instagram {
    &:before {
        content: $icon-instagram;
    }
}

.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}

.icon-youtube {
    &:before {
        content: $icon-youtube;
    }
}

.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}