.card {
    background-color: transparent;
    border: transparent;
    .alert {
        button {
            &.close {
                position: absolute;
                top: 0;
                right: 15px;
                color: $color-black-t2;
                font-weight: $text_weight_medium;
                background: none;
                border: none;
                font-size: 18px;
                cursor: pointer;
                padding: 5px 10px;
            }
        }
    }
}

.card-header {
    margin-bottom: 0;
    background-color: $color-grey-light1;
    border-bottom: 1px solid $color-divider;
    position: relative;
    // &:hover {
    //     cursor: pointer;
    // }
    span.icon-add {
        position: absolute;
        top: 50%;
        right: 0;
        font-size: 20px;
        margin-top: -10px;
        color: $color-grey-dark3;
    }
    .kdng-colour {
        width: 34px;
        height: 100%;
        display: block;
        float: left;
        position: absolute;
        border-right: 1px solid rgba(0, 0, 0, 0.125);
    }
}

.card-header-sizes {
    background: transparent;
    a {
        color: $color-primary;
        // &:hover,
        &:focus {
            color: $color-primary;
        }
    }
}

.accordion>.card {
    overflow: visible;
}