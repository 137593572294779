// $icomoon-font-path: "../fonts/iconmoon";
$icomoon-font-path: "../fonts/iconmoon-new";
$default-border-radius: 2px;
$transition-duration: 300ms;
// ----------- COLOURS ---------------
// -----------------------------------
$color-primary: rgba(24, 24, 24, 0.88);
$color-secondary: var(--shop-secondary, #202020);
// This color actually matches the secondary color, therefor we should update it to reference to $color-secondary
$color-active: $color-secondary;
// Part of the color palette, but do not appear to be in use on the website, so even if we were to update these as part of the team colour, you would see no changes made.
$color-accent1: #18f2dc;
$color-accent2: #005950;
$color-accent3: #1a2f40;
$color-accent4: #591c00;
// Leave this as it is, default values, no benefit in changing these
$color-success: #4caf50;
$color-warning: #ffeb3b;
$color-error: #f44336;
$color-disabled: #d1d1d1; // This is not on the brand colours, should this instead be updated to the -t3 text below?
// Again leave as is
$color-grey-light1: #fafafa;
$color-grey-light2: #f5f5f5;
$color-grey-light3: #ebebeb;
// Leave as is
$color-grey-dark1: #3c3c3c;
$color-grey-dark2: #282828;
$color-grey-dark3: #141414;
// The divider will match whatever background colour it sits on
$color-divider: rgba(0, 0, 0, 0.12);
// Leave as it, could potentially be updated to match the primary colour, but this could get messy pretty quick
// These styles mainly appear to be used on text on forms, product details, checkout pages etc.
$color-black: #000;
$color-black-t1: rgba(0, 0, 0, 0.88); // Normal
$color-black-t2: rgba(0, 0, 0, 0.56); // Secondary
$color-black-t3: rgba(0, 0, 0, 0.36); // Disabled
// White versions for use on darker backgrounds
$color-white: #fff;
$color-white-t0: rgba($color-white, 0.88);
$color-white-t1: rgba($color-white, 0.72); // Secondary
$color-white-t2: rgba($color-white, 0.56);
$color-white-t3: rgba($color-white, 0.48); // Disabled
// Social Media Colors
$color-google: #db4437;
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-instagram: #e4405f;
$color-linkedin: #0077b5;
$color-youtube: #c4302b;
// NEW VARIABLES ADDED TO ENABLE GULP BUILD
// Curor Styles
$cursor-disabled: not-allowed;
// Button Styles
$btn-primary-color: $color-white;
$btn-primary-bg: $color-primary;
$btn-primary-border: 1px;
$line-height: 1.5;
$brand-primary: $color-primary;
//.product-description{
//  color: $color-black-t2;
//}
.color-success {
    color: $color-success;
}

.color-black-t2 {
    color: $color-black-t2;
}

// --------- FONT STYLES --------------
// -----------------------------------
$f-styles: ( mega: ( size: 80px, lineh: 110px, spacing: -1.2px, ), jumbo: ( size: 56px, lineh: 80px, spacing: -0.6px, ), display: ( size: 40px, lineh: 44px, spacing: 0, ), headline: ( size: 32px, lineh: 48px, spacing: 0, ), title: ( size: 24px, lineh: 28px, spacing: 0.2px, ), subheader: ( size: 20px, lineh: 28px, spacing: 0.2px, ), body: ( size: 16px, lineh: 24px, spacing: 0.2px, ), caption: ( size: 14px, lineh: 20px, spacing: 0.4px, ), medium: ( size: 1.5rem, lineh: 2rem, spacing: 1px, ), small: ( size: 12px, lineh: 18px, spacing: 0.4px, ), );
// --------- FONT WEIGHTS ------------
// -----------------------------------
// ---- Light
//$text_weight_light: 200;
// ---- Regular
$text_weight_regular: 400;
// ---- Medium
$text_weight_medium: 500;
// ---- Bold
$text_weight_bold: 600;
// ---- UltraBold
$text_weight_ultrabold: 800;
// _variables.scss
@media (min-width: 544px) {
    .text_size_jumbo,
    .title__page {
        // font-size: 48px !important;
        font-size: 36px !important;
        line-height: 1.25 !important;
        letter-spacing: -0.6px !important;
    }
}

/// Over ride for Header
.site-header__menu {
    h1 {
        font-size: 16px;
        margin: 0 78px;
        font-weight: 600;
        line-height: 60px;
        @media (min-width: 768px) {
            font-size: 20px;
        }
        @media (min-width: 992px) {
            line-height: 80px;
        }
        @media (min-width: 1024px) {
            margin: 0 auto;
            max-width: 300px;
            font-weight: 700;
        }
        @media (min-width: 1440px) {
            font-size: 24px;
            margin: 0 auto;
            max-width: 100%;
        }
    }
}



router-outlet ~ * {
    // position: absolute;
    height: 100%;
    width: 100%;
}