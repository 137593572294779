.site-footer {
    background-color: black;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    position: relative;
    &__newsletter {
        border-top: 6px solid #f7375d;
        background-color: #000000;
        color: #FFFFFF;
        padding: 40px 0;
        @include media-breakpoint-up(lg) {
            padding: 20px 0;
        }
        .signup-text {
            color: #FFFFFF;
            @extend .text_size_title;
            padding-bottom: 40px;
            text-align: center;
            @include media-breakpoint-up(sm) {
                padding-bottom: 0;
                text-align: left;
            }
            @include media-breakpoint-up(lg) {
                margin-top: auto;
                margin-bottom: auto;
            }
        }
        #signup-form {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    &__logos {
    	img {
    		max-height: 120px;
    		width: auto;
    	}
    }
    &__main {
        border-top: 1px solid #20202026;
        padding-top: 80px;
        text-align: center;
        @include media-breakpoint-up(sm) {
            text-align: left;
        }
        a {
            @extend .text_colour_b-secondary;
        }
        .nav {
            li {
                padding-bottom: 16px;
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
        .social-group {
            @extend .text_size_headline;
            text-align: center;
            font-size: 20px;
            @include media-breakpoint-up(sm) {
                text-align: right;
            }
            .social-icon {
                width: 36px;
                display: inline-block;
                text-align: center;
                margin: 0 10px 10px 0;
            }
        }
        &:after {
            content: '';
            display: block;
            height: 1px;
            margin-top: 64px;
            box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
        }
    }
    &__bottom {
        @extend .text_size_small;
        padding-top: 16px;
        padding-bottom: 10px;
        text-align: center;
        @include media-breakpoint-up(sm) {
            text-align: left;
            padding-bottom: 80px;
        }
        a {
            @extend .text_colour_b-secondary;
        }
        .nav-terms {
            display: block;
            @include media-breakpoint-up(sm) {
                display: flex;
            }
            li {
                display: block;
                margin-right: 12px;
                margin-bottom: 12px;
                @include media-breakpoint-up(sm) {
                    display: inline-block;
                }
            }
        }
        .footer-copyright {
            color: white;
            margin-top: 48px;
            @include media-breakpoint-up(sm) {
                margin-top: 0;
            }
        }
    }
}

#newsletter-container {
    display: inline-flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
}

#newsletter-form > div > button {
    padding: 10px 60px;
    @media (max-width: 768px) {
        margin-top: 30px;
    }
}

body > my-footer > footer > div.container.site-footer__main > div > div > ul > li > a {
    color: #FFFFFF;
    &:hover {
        color: #ffffff82;
    }
}