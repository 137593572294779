#basket-products {
    .basket-product {
        &:last-child {
            @include media-breakpoint-up(lg) {
                margin-bottom: 32px;
            }
        }
    }
}

.basket-product {
    position: relative;
    background-color: $color-white;
    padding: 24px;
    margin-bottom: 24px;
    overflow: hidden;
    &:last-child {
        border-bottom: 0;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    &:first-child {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        border-bottom: 1px solid $color-divider;
    }
    &__details {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid $color-divider;
    }
    .btn__remove {
        position: absolute;
        top: 24px;
        right: 24px;
    }
    &__title {
        /*    color: $color-secondary;  */
        color: $color-primary;
        @extend .text_size_subheader;
        font-weight: $text_weight_bold;
    }
    .basket-refresh-img {
        width: 19px;
        height: 19px;
    }
    .basket-edit-size-btn {
        padding-left: 0px;
    }
}

.basket-nav {
    @include clearfix();
    &__continue {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        @include media-breakpoint-up(sm) {
            width: auto;
            float: left;
            margin-bottom: 0;
        }
    }
    &__clear {
        display: block;
        width: 100%;
        @include media-breakpoint-up(sm) {
            width: auto;
            float: right;
        }
    }
}

.product_details_personalisation {
    @extend .text_size_caption;
    color: $color-black-t2;
    padding-left: 0;
    @include media-breakpoint-up(md) {
        padding-left: 16px;
    }
    >div {
        margin-top: 6px;
    }
}

.col-flex-adjust {
    flex: 0 0 25%;
}