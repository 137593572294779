// @import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=3bb2005c-c183-470f-aaea-3a79905ba344");
// @font-face{
//   font-family:"Camber";
//   font-style: normal;
//   font-weight: 200;
//   src:url("../fonts/kukri-font/5d9fdae6-5a58-4e17-90a3-e7cecda92dff.eot?#iefix");
//   src:url("../fonts/kukri-font/5d9fdae6-5a58-4e17-90a3-e7cecda92dff.eot?#iefix") format("eot"),
//   url("../fonts/kukri-font/f4bed98e-3c36-42d4-a4e7-8ea5701f2b06.woff2") format("woff2"),
//   url("../fonts/kukri-font/c858b8dc-84f4-4f4a-ae0b-556a9eb2d85a.woff") format("woff"),
//   url("../fonts/kukri-font/c5c25f28-a5bd-4d94-8b41-1a6ab59bdd92.ttf") format("truetype"),
//   url("../fonts/kukri-font/6a070693-9fb7-4878-bf68-cfece22b4898.svg#6a070693-9fb7-4878-bf68-cfece22b4898") format("svg");
// }
// @font-face{
//   font-family:"Camber";
//   font-style: normal;
//   font-weight: 300;
//   src:url("../fonts/kukri-font/6e3abf20-6dff-4119-b25a-5414185f9586.eot?#iefix");
//   src:url("../fonts/kukri-font/6e3abf20-6dff-4119-b25a-5414185f9586.eot?#iefix") format("eot"),
//   url("../fonts/kukri-font/2b08b9e5-0a4d-4ba3-bb01-fc4f3ac74967.woff2") format("woff2"),
//   url("../fonts/kukri-font/59f82af5-5d5a-4c08-b4b6-26ff99e8c83a.woff") format("woff"),
//   url("../fonts/kukri-font/8072eb03-2508-4ada-87d9-0353318f5df8.ttf") format("truetype"),
//   url("../fonts/kukri-font/14401915-45ad-4b12-abdd-dc2ef675a2cd.svg#14401915-45ad-4b12-abdd-dc2ef675a2cd") format("svg");
// }
@font-face {
    font-family: "Camber";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/kukri-font/fcc79ac3-f800-4b90-ab08-1485f11e56ff.eot?#iefix");
    src: url("../fonts/kukri-font/fcc79ac3-f800-4b90-ab08-1485f11e56ff.eot?#iefix") format("eot"), url("../fonts/kukri-font/33fcb9b8-e019-46f1-a361-d0d30096f04c.woff2") format("woff2"), url("../fonts/kukri-font/0b47a0c7-9430-4540-ba9e-2471cc0e85ac.woff") format("woff"), url("../fonts/kukri-font/fe08dee4-86a4-4bfa-bf2c-f96e2eeeeafb.ttf") format("truetype"), url("../fonts/kukri-font/99940f9f-a4bc-47a1-9c63-080bc43dd205.svg#99940f9f-a4bc-47a1-9c63-080bc43dd205") format("svg");
}

@font-face {
    font-family: "Camber";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/kukri-font/4f18a648-142b-4048-906d-f7a022c92e9a.eot?#iefix");
    src: url("../fonts/kukri-font/4f18a648-142b-4048-906d-f7a022c92e9a.eot?#iefix") format("eot"), url("../fonts/kukri-font/03ba9d0f-3f36-4d23-8a1b-b5d3a9cb2dd1.woff2") format("woff2"), url("../fonts/kukri-font/a23ddd9a-cb61-43fb-b4b7-ea13de23b51b.woff") format("woff"), url("../fonts/kukri-font/eb844b7c-3e4f-4eb5-84f8-8b347ba66005.ttf") format("truetype"), url("../fonts/kukri-font/0bae5894-1d60-42f7-9eb5-9564b52d1041.svg#0bae5894-1d60-42f7-9eb5-9564b52d1041") format("svg");
}

@font-face {
    font-family: "Camber";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/kukri-font/669787e3-fe45-4ca2-8c78-fc5174c0aa8d.eot?#iefix");
    src: url("../fonts/kukri-font/669787e3-fe45-4ca2-8c78-fc5174c0aa8d.eot?#iefix") format("eot"), url("../fonts/kukri-font/08a54fbd-1bad-4e04-8861-4774eedc7a3c.woff2") format("woff2"), url("../fonts/kukri-font/c0997cde-2a49-48cb-ba76-91901fba27a7.woff") format("woff"), url("../fonts/kukri-font/31e00d9d-75f0-4654-9fda-97b2a541a0fc.ttf") format("truetype"), url("../fonts/kukri-font/627201e3-b77e-4855-a708-fabdcab6a220.svg#627201e3-b77e-4855-a708-fabdcab6a220") format("svg");
}

@font-face {
    font-family: "Camber";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("../fonts/kukri-font/b881fe01-cce3-4dc1-9047-9d1b11ed1cb9.eot?#iefix");
    src: url("../fonts/kukri-font/b881fe01-cce3-4dc1-9047-9d1b11ed1cb9.eot?#iefix") format("eot"), url("../fonts/kukri-font/1d570031-7d4c-4ee0-bb67-4040256ef0fb.woff2") format("woff2"), url("../fonts/kukri-font/05a0befc-7280-4cc4-9dcf-f2f60ca2066c.woff") format("woff"), url("../fonts/kukri-font/567314dd-605d-4615-a09f-4486711801ae.ttf") format("truetype"), url("../fonts/kukri-font/5a2af852-7962-4eb8-900f-c8bf1bd4b99f.svg#5a2af852-7962-4eb8-900f-c8bf1bd4b99f") format("svg");
}