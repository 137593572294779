.promo-bar {
	    color: $color-white;
        // padding: 18px 0;
        @extend .text_size_title;
    background-color: $color-primary;
    margin-top: 60px;
    	@include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    a {
        color: $color-white;
        // padding: 18px 0;
        display: block;
        @extend .text_size_title;
    }
    &__left {
        display: block;
        text-align: center;
        color: $color-white-t1;
        @include media-breakpoint-up(sm) {
            float: left;
        }
        strong,
        b {
            color: $color-white;
        }
    }
    &__right {
        display: block;
        text-align: center;
        @include media-breakpoint-up(sm) {
            float: right;
        }
    }
    &--red {
        background-color: $color-error;
    }
}