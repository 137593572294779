img {
    max-width: 100%;
    height: auto;
}

.logo-upload {
    img {
        background: rgba(0, 0, 0, 0.175);
        display: block;
        width: auto;
        height: auto;
        max-height: 96px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 5px;
    }
}

.logo-thumbnail {
    img {
        height: 50px;
        width: 50px;
    }
}

span.logo-thumbnail {
    width: 50px;
    height: 50px;
    box-shadow: 0px 1px 3px grey;
}

.logo-image-thumbnail {
    width: 50px;
    height: 50px;
    img {
        width: 50px !important;
        height: 50px !important;
        background-color: #666666 !important;
        &:hover {
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -o-transition: all 1s ease;
            -ms-transition: all 1s ease;
            transition: all 1s ease;
            -webkit-transform: scale(2.5);
            -moz-transform: scale(2.5);
            -o-transform: scale(2.5);
            -ms-transform: scale(2.5);
            transform: scale(2.5);
        }
    }
}

.logo-wrapper {
    background: $color-grey-light2;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid $color-divider;
    border-radius: 1px;
}

.logo-image-wrapper {
    display: inline-block;
    position: relative;
    .btn__remove--new {
        position: absolute;
        top: -12px;
        right: -11px;
    }
}

img.logo-placeholder {
    margin: 0 auto;
    display: block;
    max-height: 50px;
}

img.mh-144 {
    max-height: 144px;
    width: auto;
    padding: 15px;
}