.loader-hidden {
    visibility: hidden;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500000;
    overflow: hidden;
    background: rgba(0,0,0,0.125);
    // opacity: 1;
    //z-index: 2;
}

.progress-spinner {
    // width: 100%;
    position: fixed;
    opacity: 1;
    z-index: 500000;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    overflow: hidden;
    .mat-progress-spinner,
    .mat-spinner {
        display: inline-block;
    }
}

.progress-spinner-controls {
    margin: 10px 0;
}