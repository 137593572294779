.fit-size-guide {
    .guide-title {
        color: $color-black-t2;
        @extend .text_size_headline;
        @extend .text_weight_medium;
        @include media-breakpoint-down(sm) {
            position: relative;
            cursor: pointer;
            @include sm-get-sizes("subheader");
            letter-spacing: 2.4px;
            &:after {
                @include iconfont();
                content: $icon-keyboard_arrow_down;
                position: absolute;
                right: 0;
            }
            &.active {
                &:after {
                    content: $icon-keyboard_arrow_up;
                }
            }
        }
    }
    .guide-toggle-container {
        @include media-breakpoint-down(sm) {
            display: none;
            margin-top: 32px;
        }
        &.active {
            display: block;
        }
    }
}

.guide-find-out-more {
    margin-top: -4px;
    margin-bottom: 32px;
    display: inline-block;
}

.fit-guide__wrap,
.size-guide__wrap {
    @include media-breakpoint-down(sm) {
        background-color: $color-white;
        padding: 12px 16px;
    }
}

.guide-title {
    @include media-breakpoint-down(sm) {
        @include sm-get-sizes("subheader");
        margin-bottom: 0;
    }
}

.size-guide {
    width: 100%;
    color: $color-black-t2;
    table-layout: fixed;
    @extend .text_size_caption;
    @include media-breakpoint-up(xl) {
        @include sm-get-sizes("caption");
    }
    &__left {
        width: 77px !important;
    }
    th,
    td {
        border-bottom: 1px solid $color-divider;
        padding: 22px 0 10px;
        width: 40px;
        text-align: center;
    }
    &__fom {
        text-align: center;
        margin: 32px 0;
        display: block;
    }
}

.size-guide-mobile {
    width: 100%;
    text-align: center;
    color: $color-black-t2;
    @include media-breakpoint-up(md) {
        text-align: left;
    }
    &__title {
        font-weight: $text_weight_ultrabold;
        padding-top: 16px;
    }
    th,
    td {
        display: block;
        padding-bottom: 4px;
    }
    tr {
        &:first-child {
            .size-guide-mobile__title {
                padding-top: 0;
            }
        }
    }
}

.fit-guide {
    padding-left: 38px;
    color: $color-black-t3;
    >li {
        padding-bottom: 20px;
        position: relative;
        &:after {
            content: "";
            height: calc(100% - 36px);
            width: 2px;
            display: block;
            background-color: $color-divider;
            position: absolute;
            top: 30px;
            left: -29px;
        }
        &.active {
            .fit-guide__title {
                color: $color-black-t2;
                &:before {
                    border: 2px solid $color-secondary;
                }
                &:after {
                    content: "";
                    position: absolute;
                    height: 10px;
                    width: 10px;
                    border-radius: 10px;
                    background-color: $color-secondary;
                    display: block;
                    left: -33px;
                    top: 7px;
                }
            }
            .fit-guide__content {
                color: $color-black-t2;
            }
        }
        &:last-child {
            padding-bottom: 0;
            &:after {
                content: none;
            }
        }
    }
    &__title {
        font-weight: $text_weight_ultrabold;
        position: relative;
        margin-bottom: 6px;
        &:before {
            content: "";
            position: absolute;
            height: 20px;
            width: 20px;
            border-radius: 10px;
            border: 2px solid $color-black-t3;
            display: block;
            left: -38px;
            top: 2px;
        }
    }
    &__fom {
        padding-left: 38px;
        margin: 32px 0;
        display: block;
    }
}