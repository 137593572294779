.toggle-title {
    position: relative;
    padding-right: 25px;
    &:after {
        @include iconfont();
        content: $icon-keyboard_arrow_down;
        position: absolute;
        right: 0;
    }
    &.active {
        &:after {
            content: $icon-keyboard_arrow_up;
        }
    }
    &--left {
        padding-right: 0;
        padding-left: 16px;
        &:after {
            @include iconfont();
            content: $icon-keyboard_arrow_right;
            position: absolute;
            left: 0;
        }
        &.active {
            &:after {
                content: $icon-keyboard_arrow_down;
            }
        }
    }
}

.toggle-container {
    display: none;
    &.active {
        display: block;
    }
}