body {
    background-color: $color-grey-light2;
}

// ---------- ELEVATION --------------
// -----------------------------------
.depth {
    &--1 {
        @include sm-depth(1);
    }
    &--2 {
        @include sm-depth(1);
    }
    &--3 {
        @include sm-depth(3);
    }
    &--4 {
        @include sm-depth(4);
    }
    &--5 {
        @include sm-depth(6);
    }
    &--6 {
        @include sm-depth(8);
    }
    &--7 {
        @include sm-depth(12);
    }
    &--8 {
        @include sm-depth(16);
    }
    &--9 {
        @include sm-depth(24);
    }
}

ul,
ol {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.links--arrows {
    a {
        display: block;
        position: relative;
        padding-right: 25px;
        &:after {
            @include iconfont();
            content: $icon-keyboard_arrow_right;
            position: absolute;
            right: 0;
        }
    }
}

.anchor-link {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
    @include media-breakpoint-up(lg) {
        top: 0;
    }
}


.px-10 {
    padding: 0 10px;
}

.min-width-31rem {
    min-width: 31rem;
}

.cursor-pointer {
    cursor: pointer;
}

.mtn-48 {
    margin-top: -48px;
}