header {
    z-index: 2000;
    position: relative;
}

.site-header {
    margin-bottom: 48px;
    &__no-title {
        margin-bottom: 24px;
    }
    .hamburger {
        margin-left: 15px;
        @include media-breakpoint-up(sm) {
            margin-left: 30px;
        }
    }
    @include media-breakpoint-down(md) {
        height: 60px;
    }
    &__logo {
        display: inline-block;
        width: auto;
        position: relative;
        z-index: 10;
        @include media-breakpoint-down(md) {
            display: block;
            width: 100%;
            position: absolute;
            top: 0;
            text-align: center;
            margin-left: 0;
            background-color: black;
        }
    }
    &__logoimg {
        height: 85px;
        padding: 28px 20px 29px 0;
        @include media-breakpoint-down(md) {
            height: 60px;
            padding: 20px 0 17px;
        }
    }
}

.header-menus {
    height: 80px;
    @include sm-transition(height);
    @include media-breakpoint-down(md) {
        height: 60px;
        position: fixed;
        width: 100%;
        z-index: 9999;
        @include sm-transition(height);
    }
    background-color: #fff;
}

.kukri-shop-title {
    position: relative;
    min-height: 44px;
    top: 60px;
    h1 {
        font-size: 1rem;
        padding: 10px 0;
    }
    @include media-breakpoint-only(md) {
        top: 60px;
    }
    @include media-breakpoint-between(lg, xl) {
        top: 0;
        min-height: 56px;
        h1 {
            font-size: 2rem;
        }
    }
}

.header-sub {
    background-color: $color-white-t0;
    height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 6px;
}

.header-breadcrumbs-wrap {
    padding: 0 15px 0 0;
    line-height: 39px;
    color: $color-black-t2;
    @extend .text_size_small;
    .icon-home {
        display: inline-block;
        position: relative;
        width: 14px;
        height: 12px;
        &:before {
            position: absolute;
            font-size: 16px;
            top: -1px;
        }
    }
    .header-breadcrumb {
        display: inline-block;
        color: $color-black-t2;
        li {
            display: inline-block;
            @extend .links-black-t2;
            div {
                display: inline-block;
                font-weight: 600;
            }
            &:before {
                content: $icon-keyboard_arrow_right;
                font-family: "kukri";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }
}

#nav-menu__teams > div > div {
    justify-content: center;
}

.site-header__menu {
    position: absolute;
    top: 0;
    height: 86px;
    width: 100%;
    margin: auto;
    display: block;
    left: 0;
    color: white;
    .menu-primary {
        @include clearfix();
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        @media (max-width: 1140px) {
            margin-left: -30px;
        }
        >li {
            display: block;
            float: left;
            font-size: 16px;
            color: white;
            @media (max-width: 1335px) {
                font-size: 14px;
            }
            @media (max-width: 1075px) {
                font-size: 12px;
            }
            &:focus,
            &:hover {
                .megamenu {
                    opacity: 1;
                    visibility: visible;
                    transition: opacity 0.1s, visibility 0ms;
                    transition-delay: 0.3s;
                    background-color: black;
                }
                .menu-primary__toplink {
                    background-color: black;
                    color: white !important;
                    @include sm-transition(all, 200ms);
                    &:hover {
                        background-color: white;
                        color: black !important;
                    }
                }
            }
            >a {
                border-top: 3px solid black;
                position: relative;
                font-weight: 600;
                color: #202020;
                padding: 25px 7px 28px;
                display: block;
                color: white;
                @media (max-width: 1335px) {
                    padding: 25px 8px 28px;
                }
                @include sm-transition(all, 200ms);
                &:hover {
                    +.megamenu {
                        opacity: 1;
                        visibility: visible;
                        transition: opacity 0.1s, visibility 0ms;
                        transition-delay: 0.3s;
                    }
                    background-color: white;
                    color: $color-black;
                    border-top: 3px solid black;
                    @include sm-transition(all, 200ms);
                }
                &.active {
                    &:hover {
                        &:before {
                            opacity: 0;
                            @include sm-transition(opacity, 200ms);
                        }
                    }
                    &:before {
                        content: "";
                        height: 4px;
                        background-color: $color-secondary;
                        display: block;
                        position: absolute;
                        top: 1px;
                        left: 0;
                        width: 100%;
                        @include sm-transition(opacity, 200ms);
                    }
                }
            }
        }
    }
    h1 {
        font-size: 16px;
        margin: 0 78px;
        font-weight: 600;
        line-height: 60px;
        @media (min-width: 768px) {
            font-size: 20px;
        }
        @media (min-width: 992px) {
            line-height: 80px;
        }
        @media (min-width: 1024px) {
            margin: 0 auto;
            max-width: 300px;
            font-weight: 700;
        }
        @media (min-width: 1440px) {
            font-size: 24px;
            margin: 0 auto;
            max-width: 100%;
        }
    }
}

.search-form {
    position: relative;
    input[type="search"] {
        padding-right: 36px;
    }
    .search-submit {
        width: 40px;
        height: 40px;
        background-color: transparent;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
        cursor: pointer;
        color: $color-grey-dark1;
        @include sm-transition(all, 150ms);
        &:focus {
            @include sm-transition(all, 150ms);
            outline: none;
            color: $color-secondary;
        }
    }
    &--black {
        .search-submit {
            color: #202020;
        }
        input {
            background-color: #e1e1e1;
            color: #202020;
            box-shadow: none;
            &:focus {
                @include sm-transition(all, 150ms);
                background-color: $color-white;
                color: $color-black;
                &+.search-submit {
                    color: $color-secondary;
                }
            }
        }
    }
    &__dropdown {
        .search-submit {
            color: #282828;
        }
        input {
            background: white;
            color: #282828;
            border-radius: 0;
            line-height: 40px;
        }
    }
}

.site-header__search {
    display: block;
    height: 60px;
    float: left;
    padding: 16px 0 13px;
    margin-right: 10px;
    @include media-breakpoint-up(xl) {
        padding: 16px 10px 13px 0;
        width: 150px;
        height: 80px;
        padding: 22px 0 17px 0;
    }
}

#search-form {
    @media (max-width: 1450px) {
        display: none !important;
    }
}
body > my-header > header > div.container-fluid.header-menus.no-gutter > div > div > div.site-header__right-group > div > button {
    @media (max-width: 1450px) {
        display: block !important;
    }
}
body > my-header > header > div.container-fluid.header-menus.no-gutter > div > div > div.site-header__right-group > div > button {
    @media (max-width: 1450px) {
        margin-top: -7px;
    }
    @media (max-width: 1200px) {
        margin-top: 0;
    }
}

.site-header__right-group {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    margin-right: 15px;
    @include media-breakpoint-up(sm) {
        margin-right: 1.5rem;
    }
}

.site-header__basket-wrap {
    float: left;
    font-size: 22px;
    color: #202020;
    top: 0;
    right: 32px;
    height: 60px;
    padding: 10px 0 10px 15px;
    border-left: none;
    @include sm-transition(all);
    @include media-breakpoint-up(lg) {
        height: 80px;
        padding: 22px 0 22px 16px;
        border-left: 1px solid rgba(255, 255, 255, 0.24);
    }
    &:before {
        content: "";
        height: 4px;
        background-color: $color-secondary;
        display: block;
        position: relative;
        top: -22px;
        animation: width-to-100 0.5s;
        left: -16px;
        width: 0%;
        @include sm-transition(all);
    }
    &:hover,
    &:focus,
    &.active {
        color: #202020;
        &:before {
            //width: calc(100% + 40px);
            //@include sm-transition(all);
        }
    }
    .header-basket__icon {
        position: relative;
        left: 6px;
        z-index: 1;
        top: 4px;
        color: white;
    }
    .header-basket__count {
        @extend .text_size_small;
        position: relative;
        background-color: #e1e1e1;
        border-radius: 13px;
        padding: 4px 8px;
        font-weight: 600;
        z-index: 2;
    }
    .header-basket__value {
        @extend .text_size_caption;
        color: $color-white-t1;
        padding-left: 4px;

        .PriceCurrencySymbol {
            padding-left: 1px;
        }
    }
    .icon-local_shipping {
        position: relative;
        top: 5px;
        left: 10px;
        color: white;
    }
}

.menu-secondary {
    text-align: right;
    @include clearfix();
    @extend .text_size_small;
    float: right;
    li {
        @extend .nav-link;
        @extend .links-black-t1;
        display: block;
        float: left;
        // box-shadow: inset 1px 0 0 0 rgba(0, 0, 0, 0.12);
        border-left: solid 1px rgba(0, 0, 0, 0.12);
        padding: 0;
        font-size: 16px;
        a {
            color: $color-secondary;
            padding: 0 15px;
            line-height: 39px;
            display: block;
            @include media-breakpoint-up(xl) {
                padding: 0 15px;
            }
            &.active {
                color: $color-black-t2;
            }
        }
        &:last-child {
            a {
                padding-right: 0;
            }
        }
    }
    .action-country,
    .action-vat {
        .select2-selection--single {
            background-color: transparent;
            box-shadow: none;
        }
    }
    .action-account {
        font-weight: 600;
    }
    .action-login {
        font-weight: 600;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        left: 8px;
        position: absolute;
        top: 11px;
    }
}

.scroll-lock {
    overflow-y: scroll;
    position: fixed;
}

.links-top-group > li {
    margin-bottom: 5px;
}

.links-top-group > li > a:hover {
    color: #ffffff82;
}
.links-bottom-group > li > a:hover {
    color: #2020208f !important;
}


.mobile-menu {
    background-color: black;
    width: 100%;
    height: 100%;
    position: fixed;
    color: $color-white;
    z-index: 3;
    overflow: scroll;
    top: 60px;
    padding-bottom: 120px;
    // padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.22);
    display: none;
    &.active {
        display: block;
    }
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: $color-secondary;
    }
    .links-top-group {
        @extend .links-white;
        @extend .text_size_caption;
        // padding: 10px 0;
        padding: 15px;
        // box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.22);
        //border-bottom: 1px solid rgba(255, 255, 255, 0.22);
        li {
            a {
                // padding: 6px 0;
                display: block;
                font-size: 14px;
                line-height: 1.5;
            }
        }
    }
    .links-bottom-group {
        @extend .links-top-group;
        box-shadow: none;
        .select2-selection--single {
            background-color: transparent;
            box-shadow: none;
        }
        .select2-container {
            display: inline-block;
        }
        .select2-selection__rendered {
            color: #fff !important;
            margin: 5px 35px 5px 0 !important;
        }
    }
    .links-middle-group {
        background-color: black;
        @extend .text_size_subheader;
    }
    .toggle-title {
        // padding-top: 20px;
        // padding-bottom: 20px;
        padding: 12px 15px 11px;
        display: block;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        border-bottom: 1px solid #2020201c;
        color: white;
        &:after {
            right: 15px;
        }
    }
    .toggle-container {
        border-top: 1px solid #FFFFFF;
        background-color: black;
        @extend .text_size_body;
        padding: 15px;
    }
    .links--arrows {
        @extend .links-black-t1;
        a {
            padding: 8px 0;
        }
    }
}

.menu__group {
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.24);
    margin-bottom: 26px;
    padding-bottom: 18px;
    &:last-child {
        box-shadow: none;
        margin-bottom: 0;
    }
    &__title {
        // @extend .text_size_subheader;
        display: block;
        padding: 0.5rem 0 0.25em;
        margin-bottom: 12px;
        font-size: 16px;
        color: white;
        // white-space: nowrap;
        font-weight: 500;
        border-bottom: 1px solid white;
        //border-bottom: none !important;
        letter-spacing: 2.4px;
        text-transform: uppercase;
    }
    &__more {
        a {
            color: $color-secondary;
        }
    }
    &__bottom {
        border-top: 1px solid $color-divider;
        display: block;
        padding-top: 16px;
    }
    &__links {
        li a {
            font-size: 16px;
            margin-bottom: 8px;
            display: block;
            color: white;
            background-color: black;
            &.view-all {
                font-size: 14px;
                color: white;
                font-weight: 500;
                margin-top: 16px;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                padding-top: 8px;
            }
            &:hover,
            &.view-all:hover {
                color: #b7b7b7;;
            }
        }
    }
}

.tabs-content-pane {
    padding-top: 26px;
    padding-bottom: 26px;
}

.megatabs {
    .tabs-nav {
        @include clearfix;
        border-bottom: 1px solid $color-divider;
        margin-left: -16px;
        margin-right: -16px;
        @include media-breakpoint-up(sm) {
            margin-left: -32px;
            margin-right: -32px;
        }
        li {
            width: 33.333%;
            display: block;
            float: left;
            text-align: center;
            @extend .text_size_subheader;
            @extend .links-black-t2;
            a {
                position: relative;
                padding: 8px 0;
                display: block;
                &.active {
                    font-weight: 600;
                    color: $color-secondary;
                    &:after {
                        content: "";
                        display: block;
                        height: 2px;
                        background-color: $color-secondary;
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                    }
                }
            }
        }
    }
    .tabs-content {
        .tabs-content-pane {
            display: none;
            &.active {
                display: block;
            }
        }
    }
}

.megamenu {
    @extend .text_size_caption;
    // @include sm-depth(8);
    // box-shadow: 0 12px 12px 0px rgba(0,0,0,.175);
    // box-shadow: 0 12px 24px rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    background-color: black;
    width: 100%;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 9999;
    padding-top: 42px;
    padding-bottom: 60px;
    transition: all 0s ease;
    // display: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s linear 0.4s, visibility 0ms linear 0.41s;
    &.is-open {
        // display: block;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.1s, visibility 0ms;
        transition-delay: 0.3s;
    }
    .menu__group {
        ul {
            li {
                padding-bottom: 14px;
            }
        }
    }
    .megatabs {
        .tabs-nav {
            border-bottom: 0;
            max-width: 470px;
            margin: auto;
        }
        .tabs-content {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
    }
}

.mw-480 {
    max-width: 480px !important;
}

.mw-1140 {
    max-width: 1140px !important;
}

.search-box__mobile {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.search-box__mobile.is-active {
    z-index: 10000;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.search-box__overlay {
    top: 60px;
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    z-index: 100;
    opacity: 0;
    background: rgba(29, 29, 29, 0.8);
    transition: opacity 0.3s ease-out;
    height: 100vh;
    @include media-breakpoint-up(lg) {
        top: 80px;
    }
}

.search-box__mobile.is-active .search-box__overlay {
    opacity: 1;
    pointer-events: auto;
}

input.page-search__dropdown {
    padding: 10px 30px;
    @include media-breakpoint-up(lg) {
        padding: 20px 30px;
    }
}

button.search-close__mobile,
button.search-submit__mobile {
    background: #141414;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding: 15px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    @include media-breakpoint-up(lg) {
        padding: 20px;
        font-size: 24px;
    }
}

button.search-submit__mobile {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
}

button.search-close__mobile {
    border-left: 1px solid rgba(0, 0, 0, 0.25);
}

.btn-search {
    padding: 0;
    font-size: 22px;
    @include media-breakpoint-up(lg) {
        padding: 12px 0 5px 15px;
    }
}

.btn-search {
    &:hover:before,
    &:focus:before {
        background: transparent;
    }
}

.menu__group__sub-heading {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid white;
    margin: 15px 0 8px 0;
    color: white;
}

.menu__group__sub-heading__all {
    font-weight: 500;
    font-size: 16px;
    padding-top: 8px;
    border-top: 1px solid white;
    margin: 15px 0 8px 0;
    color: white;
}

#nav-menu__location {
    left: -100px;
    right: -20px;
    width: auto;
    padding-top: 15px;
    padding-bottom: 15px;
}

.country-row {
    flex-basis: 100%;
    margin-right: 8.33333%;
}

.country-title {
    margin-bottom: 0;
    border-bottom: 1px solid white !important;
}

.country-ul {
    display: inline-flex;
    flex-wrap: wrap;
}

.country {
    padding: 5px 20px 5px 0;
    font-weight: 400;
    margin-bottom: 0;
}

.site-header__right-group {
    display: inline-flex;
    height: 86px;
    margin-top: 5px;
}

#location-header {
    margin: auto;
    list-style: none;
    padding: 26px 0 26px 18px;
    @media (max-width: 1000px) {
        display: none;
    }
    &:hover {
        //border-top: 3px solid #202020;
        background-color: white;
        //height: 80px;
    }
}

#location-a {
    padding-right: 20px;
    color: #202020;
    font-weight: 600;
    @media (max-width: 1000px) {
        padding-right: 0;
    }
}

#location-header:hover #location-a {
    color: #000000;
}
#location-header:hover #Capa_1 {
    fill: #000000;
}
#location-header:hover #nav-menu__location {
    opacity: 1;
    visibility: visible;
    transition: opacity .1s,visibility 0s;
    transition-delay: .3s;
}

#mobile-country-picker {
    background: white;
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    top: 0px;
    padding: 20px 8%;
    z-index: 1000;
}

.country-mobile {
    padding: 5px 50px 5px 0 !important;
}

.country-row-mobile {
    margin-right: 0 !important;
}

#closeBtn:hover {
    cursor: pointer;
}

#mobile-search {
    background-color: white;
    color: #202020;
}

body > my-header > header > div.mobile-menu.d-lg-none.active > ul.links-top-group > li > a {
    color: #202020;
}

.club-country {
    margin-bottom: 10px;
    border-bottom: 1px solid #2020201c;
    font-size: initial;
    font-weight: 500;
    color: #202020;
}

.club-list {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.club-col {
    flex: 0 0 50% !important;
    max-width: 50% !important;
}

body > my-header > header > div.mobile-menu.d-lg-none.active > ul.links-middle-group > li > div.toggle-container.active > ul > li > div.menu__group__title.toggle-title.p-0 {
    border-bottom: none !important;
}

#megamenu-subtabs {
    display: inline-flex;
    width: 100%;
    justify-content: space-evenly;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

#megamenu-subtabs > div:nth-child(1) {
    padding-left: 20%;
}

#megamenu-subtabs > div:nth-child(2) {
    padding-right: 20%;
}

#kd-hr {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
}

#nz-subtab-heading:hover, #aus-subtab-heading:hover {
    color: #20202073;
}

.nz-subtab, .aus-subtab {
    display: none;
}

body > my-header > header > div.mobile-menu.d-lg-none.active > ul.links-middle-group > li:nth-child(5) > div.toggle-container.active > ul > li > div.toggle-container.p-0.active > ul > li > div {
    border-bottom: none;
}


/* Animated underline when hovering over a link - give a link the class underline and it should auto apply */
.underline{
    position: relative;
}
.underline::before{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: #383838;
    transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
@media (hover: hover) and (pointer: fine) {
    .underline:hover::before{
        left: 0;
        right: auto;
        width: 100%;
    }
}

#commonwealth-link-div {
    margin-top: 15px;
}

#commonwealth-title {
    position: absolute;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 1.5px;
    font-size: 20px;
    text-align: center;
    left: 3px;
    text-shadow: 1px 1px 7px #000000;
    padding: 10px 20px;
    text-transform: uppercase;
}

.commonwealth-mob {
    border: none;
    &:hover {
        color: #141414;
    }
}

.partners-teams-uk {
    max-width: 1600px !important;
    padding: 0;
    margin-left: -30px;
}