.delivery-price {
    color: $color-secondary;
    font-weight: 400;
}

.delivery-text {
    color: $color-black-t2;
    display: block;
    font-weight: 400;
    @extend .text_size_caption;
}

.delivery-date {
    color: $color-black-t1;
    display: block;
    font-weight: 400;
    @extend .text_size_caption;
}

.payment-methods-box {
    .accepted-payments {
        font-size: 32px;
        @include iconfont();
    }
}

.secure-payment-info {
    @extend .text_size_small;
    display: block;
    text-align: center;
    margin-top: 10px;
}

.confirm-terms {
    margin-bottom: 30px;
}

.summary-product {
    color: $color-black-t2;
    @extend .text_size_small;
    margin-bottom: 16px;
    dt {
        @extend .text_size_small;
    }
    >div {
        margin: 4px 0;
    }
    &__name {
        font-weight: 600;
        @extend .text_size_caption;
    }
    &__type {
        color: $color-black-t3;
    }
    &__more {
        margin: 20px 0;
        display: block;
    }
}

// These are eseentially the new bootstrap 4 live classes,
// TODO: When we update to the live version of bootstrap 4, remove these
.input-group-voucher {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    >.input-voucher {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
    }
}

.input-group-append {
    display: -ms-flexbox;
    display: flex;
}

#addVoucher {
    border-radius: 0 2px 2px 0;
}

span.icon-check {
    color: $color-success;
    font-size: 16px;
    margin-right: 8px;
    font-weight: 600;
}

.icon__bg-circle {
    padding: 30px;
    font-size: 30px;
    background: #3c3c3c;
    color: #fff;
    display: inline-block;
    margin-bottom: 8px;
    border-radius: 50%;
}

.checkout__stepper {
    z-index: 100;
    text-align: center;
}

// min width 768px
@media screen and (min-width: 768px) {
    .progress-bar_layout {
        width: 400px;
        max-width: 614px;
        margin: 0 auto 32px;
        position: relative;
    }
}

// 
.progress-bar_progressBar {
    position: absolute;
    display: block;
    width: 90%;
    height: 1px;
    top: 18px;
    background-color: #d8d8d8;
    left: 5%;
}

.progress-bar_bar {
    width: 0;
    height: 100%;
    background-color: #000;
}

.progress-bar_container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-direction: row;
    flex-direction: row;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    & li {
        margin-bottom: 16px;
    }
}

.progress-bar_step {
    font-size: 16px;
    text-decoration: none;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    font-size: 14px;
    background: none;
    text-align: center;
    word-break: break-all;
}

.bullet {
    font-weight: 200;
    letter-spacing: normal;
    line-height: 20px;
    list-style: none;
    text-align: center;
    word-break: break-all;
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: $white;
    color: #3c3c3c;
    margin: 0 auto 8px;
    z-index: 2;
    &__active {
        background-color: var(--shop-secondary, #00a695);
        & .bullet--text {
            color: $white;
        }
    }
    &__complete {
        background-color: #f0f0f0;
        & .bullet--text {
            color: var(--shop-secondary, #00a695);
        }
    }
    & .icon-check {
        margin-right: 0;
    }
}

a.badge-primary {
    color: $white;
    background-color: var(--shop-secondary, #00a695);
    text-shadow: 1px 0 rgba(0, 0, 0, 0.5);
    &.focus,
    &:focus {
        outline: 0;
        box-shadow: none;
    }
    &:hover,
    &:focus {
        color: $white;
        background-color: var(--shop-secondary, #00a695);
    }
}

.address_billing--primary {
    background: white;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 3px;
}

.site-header-checkout {
    height: 80px;
}

.btn__checkout--delivery-option {
    background: rgba(0, 0, 0, 0.025);
    color: #3c3c3c;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 24px 32px;
    width: 100%;
    min-height: 124px;
}

.btn__checkout--delivery-option.active {
    background: white;
    color: #3c3c3c;
}

.delivery-icon-text {
    margin-bottom: 0;
    font-weight: 500;
}

.icon__checkout--delivery-icon {
    font-size: 28px;
    margin-bottom: 6px;
    display: block;
    color: var(--shop-secondary, #00a695);
    opacity: 0.85;
}