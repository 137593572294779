#img-back,
#img-front {
    position: relative;
    display: none;
}

#canvasFront,
#canvasBack {
    max-width: 100%;
    z-index: 1000;
    display: inline-block;
}

#canvasFrontLogo,
#canvasBackLogo {
    max-width: 100%;
    z-index: 5000;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
}

#canvasFrontText,
#canvasBackText {
    max-width: 100%;
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
}