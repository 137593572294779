/* Colors */

$primary-color: rgba(0, 0, 0, 0.56);
$secondary-color: #202020;

/* Responsive classes */


/*helper classes*/

.m-t-5 {
    margin-top: 5rem;
}

.m-t-60 {
    margin-top: 60px;
}

.m-b-60 {
    margin-bottom: 60px;
}

.btn-m-x {
    margin: 1rem 0 1.8rem;
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.float-right {
    float: right
}

.generic {
    &-heading {
        border-bottom: 1px solid $color-divider;
        padding-bottom: 24px;
        margin: 32px 0;
        color: $color-black-t2;
        @extend .text_weight_medium;
        @extend .text_size_subheader;
    }
}

.shaded {
    &-pill {
        border-bottom: 1px solid $color-secondary;
        padding: 6px 0;
        width: auto;
        p {
            margin-bottom: 0rem!important;
        }
    }
    &-text {
        color: $color-secondary;
    }
}


/* Grid layout*/

section.row {
    padding: 10px;
    margin: 0 0 5px 0;
    box-sizing: border-box;
    border: 1px solid $color-accent1;
    border-radius: 10px;
}


/* Custom Styling */

.custom {
    label {
        display: block;
        @include sm-get-sizes(medium);
        @include media-breakpoint-up(lg) {
            background: $color-white;
        }
    }
    input[type="radio"] {
        position: absolute;
        left: -9999px;
    }
    input[type="radio"]+label {
        @include media-breakpoint-up(lg) {
            width: 30%;
            border-radius: 1rem;
            float: left;
            margin: 5px;
            padding: 25px;
        }
    }
    input[type="radio"]:checked+label {
        @include media-breakpoint-up(lg) {
            background-color: $color-secondary;
            color: $color-white;
        }
    }
    input[type="radio"]+label:before {
        content: '';
        transition: 0.5s ease-in all;
        @include media-breakpoint-down(lg) {
            border-radius: 1em;
            width: 1em;
            height: 1em;
            display: inline-block;
            border: 0.125em solid $color-secondary;
        }
        @include media-breakpoint-up(lg) {}
    }
    input[type="radio"]:checked+label:before {
        background-color: $color-secondary!important;
        @include media-breakpoint-up(lg) {
            color: $color-white;
        }
    }
    p {
        line-height: 1;
    }
}


/* Sidebar Dashboard */

.sidebar-db {
    a {
        margin: 6rem 0;
    }
    >a:first-child {
        border-left: 3px solid $color-secondary;
        padding-left: 0.5rem;
    }
    ul li {
        font-size: 0.8em;
        padding-left: 1rem;
        @include media-breakpoint-down(sm) {
            font-size: 1rem;
            line-height: 22px;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 3rem;
    }
}

.quick-bag {
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
        position: fixed;
        width: 100%;
        height: 70px;
        position: fixed;
        bottom: 0;
        z-index: 3;
        background: $color-white;
        border-top: 1px solid $secondary-color;
        -webkit-box-shadow: -11px -11px 20px -3px rgba(0, 0, 0, 0.18);
        -moz-box-shadow: -11px -11px 20px -3px rgba(0, 0, 0, 0.18);
        box-shadow: -11px -11px 20px -3px rgba(0, 0, 0, 0.18);
        ul {
            display: flex;
            justify-content: center;
            li {
                a {
                    display: block;
                    padding: 28px 12px;
                }
            }
        }
    }
}


/* Products pages */

.products {
    &-titles {
        font-weight: $text_weight_medium;
        margin-bottom: 0;
        color: $secondary-color;
    }
}


/* Styling for form that has 12 cols */

@include media-breakpoint-only(lg) {
    .form-overrides-m-p .col-lg-1 {
        padding: 0;
        padding: 0.2em;
    }
}


/* Tabular data*/

.logos- {
    &table {
        @include media-breakpoint-down(md) {
            th {
                display: none;
            }
            td {
                display: block;
                padding-left: 50%;
                position: relative;
            }
            td:before {
                position: absolute;
                left: 6px;
            }
            tr {
                margin-bottom: 8rem;
            }
            td:nth-of-type(1)::before {
                content: "Code";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(2)::before {
                content: "Description";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(3)::before {
                content: "Reference";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(4)::before {
                content: "Created By";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(5)::before {
                content: "View Image";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(5)::before {
                content: "Add to Account";
                font-weight: $text_weight_bold;
            }
        }
    }
}

.lod- {
    &table {
        @include media-breakpoint-down(md) {
            th {
                display: none;
            }
            td {
                display: block;
                padding-left: 50%;
                position: relative;
            }
            td:before {
                position: absolute;
                left: 6px;
            }
            tr {
                margin-bottom: 8rem;
            }
            td:nth-of-type(1)::before {
                content: "Kukri Code";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(2)::before {
                content: "Your Designs Name";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(3)::before {
                content: "Search Tag";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(4)::before {
                content: "Design";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(5)::before {
                content: "Actions";
                font-weight: $text_weight_bold;
            }
        }
    }
}

.c- {
    &submenu {
        li {}
    }
    &table {
        th:nth-of-type(4)::before {
            text-align: center!important;
        }
        @include media-breakpoint-down(md) {
            th {
                display: none;
            }
            td {
                display: block;
                padding-left: 50%;
                position: relative;
            }
            td:before {
                position: absolute;
                left: 6px;
            }
            tr {
                margin-bottom: 8rem;
            }
            td:nth-of-type(1)::before {
                content: "Ref";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(2)::before {
                content: "Date";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(3)::before {
                content: "Order Status";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(4)::before {
                content: "";
            }
            td:nth-of-type(5)::before {
                content: "Actions";
                font-weight: $text_weight_bold;
            }
        }
    }
}

.kitbag- {
    &table {
        @include media-breakpoint-down(md) {
            th {
                display: none;
            }
            td {
                display: block;
                padding-left: 50%;
                position: relative;
            }
            td:before {
                position: absolute;
                left: 6px;
            }
            tr {
                margin-bottom: 8rem;
            }
            td:nth-of-type(1)::before {
                content: "Name";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(2)::before {
                content: "Created Date";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(3)::before {
                content: "Last Updated Date";
                font-weight: $text_weight_bold;
            }
            td:nth-of-type(4)::before {
                content: "Image";
                font-weight: $text_weight_bold;
            }
        }
        svg:not(.bi) {
            width: 50px;
        }
    }
}

.custom-file-control:lang(en)::before {
}

.kukri-dashboard-tabs {
    color: #fff!important;
    cursor: default;
    background-color: rgba(24, 24, 24, 0.88);
}

.mt-n24px {
    margin-top: -24px;
}
