body {
    color: $color-black-t1;
    @include sm-get-sizes("caption");
    @include media-breakpoint-up(sm) {
        @include sm-get-sizes("body");
    }
}

small,
.small {
    @extend .text_size_small;
}

strong {
    @extend .text_weight_ultrabold;
}

a {
    color: $color-active;
    &:hover,
    &:focus {
        color: rgba($color-active, 0.9);
        outline: none;
        text-decoration: none;
    }
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.25);
}
.disabled:hover {
    cursor: not-allowed;
}

.title {
    &__page {
        @extend .text_size_jumbo;
        font-weight: $text_weight_ultrabold;
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: left;
        }
        &:after {
            content: "";
            display: block;
            height: 12px;
            width: 64px;
            margin: 5px auto 0 auto;
            background-color: $color-secondary;
            @include media-breakpoint-up(md) {
                margin: 5px 0 0 0;
            }
        }
    }
    &__socialbox {
        color: $color-black-t2;
        @extend .text_size_title;
        font-weight: $text_weight_bold;
    }
    &--white {
        color: $color-white;
    }
}

.head {
    &__checkout {
        color: $color-black-t2;
        @extend .text_size_subheader;
        @extend .text_weight_medium;
        border-bottom: 1px solid $color-divider;
        padding-bottom: 12px;
        margin-bottom: 24px;
    }
}

.body__lg {
    @extend .text_size_subheader;
    color: $color-black-t2;
    date {
        color: $color_black-t1;
    }
}

.links-secondary {
    a {
        color: $color-secondary;
    }
}

.links-black-t1 {
    a {
        color: $color-black-t1;
    }
}

.links-black-t2 {
    a {
        color: $color-black-t2;
    }
}

.links-white {
    a {
        color: $color-white;
    }
}

.list-style {
    li {
        padding-bottom: 16px;
        padding-left: 8px;
        position: relative;
        &:last-child {
            padding-bottom: 0;
        }
        &:before {
            position: absolute;
            content: "\00B7";
            left: 1px;
        }
    }
}

// --------- FONT SIZES --------------
// -----------------------------------
// 80px
.text_size_mega {
    @include sm-get-sizes("jumbo");
    @include media-breakpoint-up(sm) {
        @include sm-get-sizes("mega");
    }
}

// 56px
.text_size_jumbo {
    @include sm-get-sizes("display");
    @include media-breakpoint-up(sm) {
        @include sm-get-sizes("jumbo");
    }
}

// 40px
.text_size_display {
    @include sm-get-sizes("headline");
    @include media-breakpoint-up(sm) {
        @include sm-get-sizes("display");
    }
}

// 32px
.text_size_headline {
    @include sm-get-sizes("title");
    @include media-breakpoint-up(sm) {
        @include sm-get-sizes("headline");
    }
}

// 24px
.text_size_title {
    @include sm-get-sizes("subheader");
    @include media-breakpoint-up(sm) {
        @include sm-get-sizes("title");
    }
}

// 20px
.text_size_subheader {
    @include sm-get-sizes("body");
    @include media-breakpoint-up(sm) {
        @include sm-get-sizes("subheader");
    }
}

// 16px
.text_size_body {
    @include sm-get-sizes("caption");
    @include media-breakpoint-up(sm) {
        @include sm-get-sizes("body");
    }
}

// 14px
.text_size_caption {
    @include sm-get-sizes("small");
    @include media-breakpoint-up(sm) {
        @include sm-get-sizes("caption");
    }
}

// 12px
.text_size_small {
    @include sm-get-sizes("small");
}

.text-x-small {
    font-size: 12px !important;
}

// --------- FONT WEIGHTS ------------
// -----------------------------------
//.text_weight_light {
//    font-weight: $text_weight_light;
//}
.text_weight_regular {
    font-weight: $text_weight_regular;
}

.text_weight_medium {
    font-weight: $text_weight_medium;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}

.text_weight_bold {
    font-weight: $text_weight_ultrabold;
}

.text_weight_ultrabold {
    font-weight: $text_weight_ultrabold;
}

// --------- FONT COLOURS ------------
// -----------------------------------
.text_colour_b-normal {
    color: $color-primary;
}

.text_colour_b-secondary {
    color: $color-black-t2;
}

.text_colour_b-disabled {
    color: $color-black-t3;
}

.text_colour_w-normal {
    color: $color-white;
}

.text_colour_w-secondary {
    color: $color-white-t1;
}

.text_colour_w-disabled {
    color: $color-white-t3;
}

.text_colour_active {
    color: $color-secondary;
}

.text_colour_accent1 {
    color: $color-accent1;
}

.text_colour_accent2 {
    color: $color-accent2;
}

.text_colour_accent3 {
    color: $color-accent3;
}

.text_colour_accent4 {
    color: $color-accent4;
}

.text_colour_success {
    color: $color-success;
}

.text_colour_warning {
    color: $color-warning;
}

.text_colour_error {
    color: $color-error;
}

@include media-breakpoint-down(md) {
    dl dd {
        display: block;
    }
    dd {
        &:before {
            content: none;
        }
    }
}

dl {
    color: $color-black-t2;
    @include sm-get-sizes("caption");
    margin: 0;
    dt {
        display: block;
        @include media-breakpoint-up(md) {
            display: inline-block;
        }
        font-weight: $text_weight_regular;
    }
    dd {
        display: block;
        @include media-breakpoint-up(md) {
            display: inline;
            &:after {
                display: block;
                content: "";
            }
        }
        font-weight: $text_weight_ultrabold;
        &:before {
            font-weight: $text_weight_regular;
            margin: 0 6px;
            @include media-breakpoint-up(md) {
                content: "\00B7";
            }
        }
    }
}

.price-display {
    color: $color-black-t2;
    @extend .text_weight_ultrabold;
}

ins {
    line-height: 1;
    font-size: 16px;
    text-decoration: none;
    border-bottom: 0;
    .price-display {
        color: $color-error !important;
        text-decoration: none;
    }
}

del {
    display: inline-block;
    margin-right: 8px;
    color: $color-black-t2;
    .price-display {
        text-decoration: none;
        font-size: 20px;
        display: block;
        font-weight: 600;
        line-height: 1.25;
    }
}

.text-small {
    font-size: 16px !important;
}

// _type.scss
ins .price-display {
    color: $color-grey-dark1 !important;
    /* text-decoration: none; */
    // font-size: 2rem;
    font-size: 26px; // review
}

del+ins .price-display {
    color: $color-error !important;
}

.font--light {
    color: $color-black-t2 !important; // display: block !important;
    font-weight: $text_weight_medium !important;
    /* letter-spacing: 1.4px !important; */
    // text-transform: uppercase !important;
    font-size: 18px !important;
    line-height: 1.35 !important;
}

.text_size_display,
.product-details .product-info__price {
    font-size: 32px;
    line-height: 1;
    letter-spacing: 0;
}

.text_size_jumbo,
.title__page {
    font-size: 24px;
    line-height: 1.125;
    letter-spacing: 0;
    @include media-breakpoint-up(md) {
        font-size: 40px; // line-height: 44px;
        letter-spacing: 0;
    }
}

.title {
    &__page {
        @extend .text_size_jumbo;
        font-weight: $text_weight_ultrabold;
        text-align: left;
        &:after {
            content: "";
            display: block;
            height: 8px;
            width: 64px;
            margin: 10px 0;
            background-color: $color-secondary;
            @include media-breakpoint-up(md) {
                height: 8px;
            }
        }
    }
}

.text_size_jumbo,
.title__page {
    font-size: 24px;
    line-height: 1.125;
    letter-spacing: 0;
    @include media-breakpoint-up(md) {
        font-size: 40px; // line-height: 44px;
        letter-spacing: 0;
    }
}

.text-small {
    font-size: 16px !important;
}

.font-size-16px {
    font-size: 16px !important;
    letter-spacing: 0;
}

.text-muted {
    color: $color-black-t2 !important;
}