// Base styles
.kukri-dropdown-btn {
    display: inline-block;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: $input-btn-border-width solid transparent; // @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-border-radius);
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition); // Share hover and focus styles
    @include hover-focus {
        text-decoration: none;
    }
    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    } // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        cursor: $cursor-disabled;
        opacity: .65;
        @include box-shadow(none);
    }
    &:active,
    &.active {
        background-image: none;
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
}
// Button variants
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
// @include button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))
// @include button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color)

// Button sizes
// @include button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius)
// Alternate buttons
// .kukri-dropdown-btn-primary {
//   @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
// }
// .kukri-dropdown-btn-secondary {
//   @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
// }
// .kukri-dropdown-btn-info {
//   @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
// }
// .kukri-dropdown-btn-success {
//   @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
// }
// .kukri-dropdown-btn-warning {
//   @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
// }
// .kukri-dropdown-btn-danger {
//   @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
// }
// Remove all backgrounds
// .kukri-dropdown-btn-outline-primary {
//   @include button-outline-variant($btn-primary-bg);
// }
// .kukri-dropdown-btn-outline-secondary {
//   @include button-outline-variant($btn-secondary-border);
// }
// .kukri-dropdown-btn-outline-info {
//   @include button-outline-variant($btn-info-bg);
// }
// .kukri-dropdown-btn-outline-success {
//   @include button-outline-variant($btn-success-bg);
// }
// .kukri-dropdown-btn-outline-warning {
//   @include button-outline-variant($btn-warning-bg);
// }
// .kukri-dropdown-btn-outline-danger {
//   @include button-outline-variant($btn-danger-bg);
// }
// Button Sizes
//
// @include button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius)
.kukri-dropdown-btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $line-height, $btn-border-radius-lg);
}

.kukri-dropdown-btn-sm {
    // line-height: ensure proper height of button next to small input
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $line-height, $btn-border-radius-sm);
} /// scrolling of dropdown div
.kukri-scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

// New styling added to fix too many logo positiong apeparing and going off screen
.dropdown-menu__logo-position {
	width: 100%;
	max-height: 240px;
	overflow-y: scroll;
}