.kukri- {
    &ms-logo {
        // @extend .site-header__logoimg;
	   max-height: 60px;
	   width: auto;
	   line-height: 60px;
	   top: 10px;
	   position: relative;
        @include media-breakpoint-down(md) {
            height: 40px;
        }
    }
/*     &managed-shop { */
/*         padding-top: 68px; */
/*         @include media-breakpoint-up(lg) { */
/*             padding-top: 0; */
/*         } */
/*     } */
/*     &slider { */
/*         .slick-track { */
/*             @include media-breakpoint-down(md) { */
/*                 margin: 20px auto; */
/*             } */
/*         } */
/*     } */
/*     &ms-header { */
/*         width: 100%; */
/*         background-color: black; */
/*         height: 100px; */
/*         img { */
/*             height: 80px; */
/*         } */
/*     } */
    &ms-menu {
    height: 80px;
    width: 100%;
    margin: auto;
    display: block;
    &__background-image {
        background-repeat: no-repeat;
	    background-size: cover;
	    background-position: center;
    }
    @include media-breakpoint-down(md) {
		height: 60px;
		position: fixed;
		width: 100%;
		z-index: 9999;
		transition: height .3s cubic-bezier(.45,.22,.41,.92);
		top: 0;            
	}
        .site-header__logo {
            z-index: 10;
            display: inline-block;
            width: auto !important;
            padding: 0 !important;
            position: relative;
            height: auto !important;
        }
    }
    &ms-m-menu-tweak {
        &:after {
            content: "" !important;
        }
    }
    .site-header__menu {
        position: static;
    }
    &toggle-ms-menu {
        padding-top: 20px;
        padding-bottom: 20px;
        display: block;
        font-weight: 600;
        cursor: pointer;
    }
    &header-sub {
        //display: none;
        border-bottom: 1px solid rgba(0,0,0,0.12);
        //@include media-breakpoint-up(sm) {
        //    display: block;
        //}
    }
}

.site-header__menu-ms {
    height: 80px;
    width: 100%;
    margin: auto;
    display: block;
    .menu-primary {
        @include clearfix();
        margin: auto;
        >li {
            display: block;
            float: left;
            >a {
                position: relative;
                font-weight: 600;
                color: $color-white;
                padding: 28px 12px;
                display: block;
                @include sm-transition(all, 200ms);
                text-shadow: 0px 1px 0px rgba(0,0,0,0.25);
                &:hover,
                &:focus {
                    +.megamenu {
                        display: block;
                    }
                    background-color: $color-grey-light1;
                    color: $color-black !important;
                    @include sm-transition(all, 200ms);
                    text-shadow: none;
                }
                &.active {
                    &:hover {
                        &:before {
                            opacity: 0;
                            @include sm-transition(opacity, 200ms);
                        }
                    }
                    &:before {
                        content: '';
                        height: 4px;
                        background-color: $color-secondary;
                        display: block;
                        position: absolute;
                        top: 1px;
                        left: 0;
                        width: 100%;
                        @include sm-transition(opacity, 200ms);
                    }
                }
            }
        }
    }
}


.header-menus-ms {
    height: 80px;
    @include sm-transition(height);
    @include media-breakpoint-down(md) {
        height: 60px;
        position: fixed;
        top: 60px;
        width: 100%;
        z-index: 9999;
        @include sm-transition(height);
    }
    background-color: $color-black;
}

.header-ms .mobile-menu {
    top: 120px;
}

ul.alphabet-breaker li {
    margin-bottom: 16px;
}
