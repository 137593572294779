.product-details {
    .product-gallery {
        margin-bottom: 32px;
    }
    .product-info {
        &__adjust-position {
            @include media-breakpoint-up(md) {
                right: 82px;
            }
        }
        &__short-desc {
            @extend .text_size_subheader;
            color: $color-black-t2;
            margin-bottom: 24px;
            text-align: left;
        }
        &__price {
            @extend .text_size_display;
            margin-bottom: 20px;
            text-align: left;
            @include media-breakpoint-up(md) {
                text-align: left;
            }
        }
        &__atts {
            text-align: center;
            margin-top: 32px;
            @include media-breakpoint-up(md) {
                text-align: left;
                margin-top: 0;
            }
            dl {
                @extend .text_size_body;
            }
        }
        &__tabs {
            position: relative;
            margin-top: 30px;
            &__links {
                position: relative;
                @include clearfix();
                &__offtheshelf,
                &__personalise {
                    @include sm-get-sizes("body");
                    display: block;
                    width: 50%;
                    float: left;
                    text-align: center;
                    padding: 8px 16px;
                    font-weight: $text_weight_medium;
                    color: $color-black-t2;
                    &.active {
                        border-bottom: 2px solid $color-secondary;
                        font-weight: $text_weight_ultrabold;
                        color: $color-secondary;
                    }
                }
            }
            &__offtheshelf {
                border-top: 0px solid transparent;
                background-color: white;
                padding: 34px 24px 48px 24px;
                display: none;
                .form-group>label {
                    @extend .text_size_subheader;
                    @extend .text_weight_medium;
                    color: $color-black-t2;
                    display: block;
                }
                &.active {
                    display: block;
                    border-bottom: 0px solid $color-secondary;
                    font-weight: 500;
                    color: $color-secondary;
                    background: $color-white;
                }
            }
            &__personalise {
                border-top: 0px solid transparent;
                background-color: $color-white;
                padding: 30px;
                top: 0;
                right: 0;
                min-height: 350px;
                display: none;
                z-index: 2;
                @include media-breakpoint-up(lg) {
                    position: relative;
                    left: -720px;
                }
                @include media-breakpoint-only(lg) {
                    position: relative;
                    left: -500px;
                    width: 968px;
                }
                @include media-breakpoint-up(xl) {
                    position: relative;
                    width: 1090px;
                    left: -720px;
                }
                @media (min-width: 1233px) {
                    width: 1430px;
                }
                &.active {
                    display: block;
                    border-bottom: 0px solid $color-secondary;
                    font-weight: 500;
                    color: $color-secondary;
                    background: $color-white;
                }
            }
        }
    }
}

.teammates-header-row {
    //   border-top: 1px solid $color-divider;
    border-bottom: 1px solid $color-divider;
    padding: 0 0 16px;
}

.teammates-main-row {
    padding: 24px 0;
    border-bottom: 1px solid $color-divider;
    margin-bottom: 24px;
}

.custom-addon {
    font-weight: $text_weight_ultrabold;
    color: $color-grey-dark3;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    @include sm-get-sizes("body");
    &__subtitle {
        @include sm-get-sizes("small");
        font-weight: $text_weight_medium;
    }
    .price-display {
        color: $color-secondary;
    }
}

.teammate-single {
    overflow: hidden;
    .btn__remove {
        margin: 0 0 16px 0;
        @include media-breakpoint-up(lg) {
            margin-top: 6px;
        }
        @include media-breakpoint-down(md) {
            padding-left: 30px;
            // &:hover,
            &:focus {
                &:before {
                    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
                }
            }
            &:active {
                &:before {
                    text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
                }
            }
            &:before {
                font-size: 22px;
                font-weight: $text_weight_regular;
                @include iconfont();
                content: $icon-cancel;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:after {
                content: none;
            }
        }
    }
}

.toggle-teammate-title {
    display: block;
    padding: 0 0 16px 0;
    @include sm-get-sizes("body");
    @include media-breakpoint-up(lg) {
        display: none;
    }
    .toggle-teammate-title__name {
        @extend .text_weight_medium;
        color: $color-black-t1;
        position: relative;
        display: block;
        padding-right: 20px;
        border-bottom: 1px solid #d4d4d4;
        padding-bottom: 14px;
        &:after {
            @include iconfont();
            content: $icon-keyboard_arrow_down;
            position: absolute;
            right: 0;
            top: 0;
        }
        &.active {
            &:after {
                content: $icon-keyboard_arrow_up;
            }
        }
    }
}

.toggle-teammate-content {
    display: none;
    &.active {
        display: block;
        margin-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
        display: block;
    }
}

#bulk-add-teammates {
    width: 100%;
    display: block;
    float: right;
    margin-top: 20px;
    @include media-breakpoint-up(md) {
        width: auto;
        margin-top: 0;
    }
}

#js-addteammate {
    @include sm-get-sizes("body");
    display: block;
    color: $color-black-t1;
    font-weight: $text_weight_ultrabold;
    width: 160px;
    &:before {
        @include iconfont;
        content: $icon-add;
        margin-right: 8px;
        font-weight: $text_weight_ultrabold;
    }
}

.zoom {
    display: inline-block;
    position: relative;
}

.product-info__short-desc {
    dl {
        font-size: 16px !important;
        line-height: 1.5 !important;
        color: $color-primary !important;
        dt {
            font-weight: 600 !important;
        }
        dd {
            font-weight: 500 !important;
        }
    }
}

#product-info-tabs {
    min-height: 500px;
}

.product-details .product-info__tabs {
    position: relative;
    margin-top: 24px;
}

// Tabs
.product-info__tabs {
    .tab-content {
        border: 1px solid $color-divider;
        margin-top: -1px;
    }
}

#product-navigation-tabs {
    border-bottom: 1px solid $color-black-t3;
    border-top: 1px solid rgba(125, 125, 125, 0.125);
}

.product-tabs .nav-link.active,
.product-tabs .menu-secondary li.active,
.menu-secondary .product-tabs li.active {
    color: #464a4c;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.88);
    font-weight: 500;
}